import { useEffect } from "react";
import Testimonial from "../Home/testimonial";

function StudentWall() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return(
    <>
    <div id="section-subheader">
      <div className="sigma_subheader style-8 bg-cover bg-center dark-overlay"
      style={{ backgroundImage: 'url(assets/img/finance/1920x801.jpg)' }}>
        <div className="container">
          <div className="sigma_subheader-inner">
            <h1 className="sigma_stroke-text">Student Wall</h1>
          </div>
        </div>
      </div>
    </div>
    <div id="section-pricing">
      <div className="section section-padding">
        <div className="container">
          <div className="section-title centered has-element">
            <h3 className="title">Student Wall</h3>
            <h4>Our Recent Graduates</h4>
            <div className="d-flex align-items-center justify-content-center">
              <span className="lines"></span>
              <span className="circle"></span>
              <span className="lines"></span>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-4 col-md-6">
              <div className="sigma_pricing style-3">
                <div className="sigma_pricing-top">
                  <img src="assets/img/studentwall/1.jpg" alt="studentwall" />
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="sigma_pricing style-3">
                <div className="sigma_pricing-top">
                  <img src="assets/img/studentwall/2.jpg" alt="studentwall" />
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="sigma_pricing style-3">
                <div className="sigma_pricing-top">
                  <img src="assets/img/studentwall/3.jpg" alt="studentwall" />
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="sigma_pricing style-3">
                <div className="sigma_pricing-top">
                  <img src="assets/img/studentwall/6.jpg" alt="studentwall" />
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="sigma_pricing style-3">
                <div className="sigma_pricing-top">
                  <img src="assets/img/studentwall/5.jpg" alt="studentwall" />
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="sigma_pricing style-3">
                <div className="sigma_pricing-top">
                  <img src="assets/img/studentwall/4.jpg" alt="studentwall" />
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="sigma_pricing style-3">
                <div className="sigma_pricing-top">
                  <img src="assets/img/studentwall/7.jpg" alt="studentwall" />
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="sigma_pricing style-3">
                <div className="sigma_pricing-top">
                  <img src="assets/img/studentwall/9.jpg" alt="studentwall" />
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="sigma_pricing style-3">
                <div className="sigma_pricing-top">
                  <img src="assets/img/studentwall/8.jpg" alt="studentwall" />
                </div>
              </div>
            </div>
          </div>
          <div>
            <Testimonial/>
          </div>
        </div>
      </div>
    </div>
    <div id="section-to-top">
      <div className="sigma_top">
        <i className="far fa-arrow-up"></i>
      </div>
    </div>
    </>
  );
}

export default StudentWall;