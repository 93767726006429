import { useEffect, useRef, useState } from "react";
import AirBrakes from "./airbrakes";
import Class1MELT from "./class1melt";
import Class1MELTEquivalency from "./class1meltequivalencyandexperience";
import Class1Refresher from "./class1refresher";
import Class3Hourly from "./class3hourlyinstruction";
import Class3Intro from "./class3intro";
import Class3Advanced from "./class3advanced";
import LCVTrainingDetails from "./lcvtrainingdetails";
import LoadSecurement from "./loadsecurement";
import LogBook from "./logbook";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";

function CourseDetails() {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const id = queryParams.get("id");
  const [selectedCourse, setSelectedCourse] = useState(id ?? null);
  const courseRef = useRef(null);

  useEffect(() => {
    if (courseRef.current) {
      courseRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [selectedCourse]);

  const AllCourses = () => {
    return (
      <div className="widget widget-categories" key={id}>
        <h5 className="widget-title">Other Courses</h5>
        <ul>
          <li>
            <Link
              to="/coursedetails?id=1"
              className={selectedCourse === 1 ? "active" : ""}
            >
              Air Brakes
            </Link>
          </li>
          <li>
            <Link to="/coursedetails?id=2">Class 1 MELT Course</Link>
          </li>
          <li>
            <Link to="/coursedetails?id=3">
              Class 1 MELT - Equivalency and Experience
            </Link>
          </li>
          <li>
            <Link to="/coursedetails?id=4">Class 1 - Refresher</Link>
          </li>
          <li>
            <Link to="/coursedetails?id=5">
              Class 3 Drivers Training - Hourly Instruction
            </Link>
          </li>
          <li>
            <Link to="/coursedetails?id=6">
              Class 3 Drivers Training - Introductory
            </Link>
          </li>
          <li>
            <Link to="/coursedetails?id=7">Class 3 Training - Advanced</Link>
          </li>
          <li>
            <Link to="/coursedetails?id=8">Load Securement Training</Link>
          </li>
          <li>
            <Link to="/coursedetails?id=9">
              Long Combination Vehicle (LCV) Training
            </Link>
          </li>
          <li>
            <Link to="/coursedetails?id=10">
              Log Book/Hour of Service
            </Link>
          </li>
        </ul>
      </div>
    );
  };

  const getComponent = () => {
    switch (selectedCourse) {
      case "1":
        return (
          <AirBrakes
            setSelectedCourse={setSelectedCourse}
            allCourses={<AllCourses />}
          />
        );
      case "2":
        return (
          <Class1MELT
            setSelectedCourse={setSelectedCourse}
            allCourses={<AllCourses />}
          />
        );
      case "3":
        return (
          <Class1MELTEquivalency
            setSelectedCourse={setSelectedCourse}
            allCourses={<AllCourses />}
          />
        );
      case "4":
        return (
          <Class1Refresher
            setSelectedCourse={setSelectedCourse}
            allCourses={<AllCourses />}
          />
        );
      case "5":
        return (
          <Class3Hourly
            setSelectedCourse={setSelectedCourse}
            allCourses={<AllCourses />}
          />
        );
      case "6":
        return (
          <Class3Intro
            setSelectedCourse={setSelectedCourse}
            allCourses={<AllCourses />}
          />
        );
      case "7":
        return (
          <Class3Advanced
            setSelectedCourse={setSelectedCourse}
            allCourses={<AllCourses />}
          />
        );
        case "8":
        return (
          <LoadSecurement
            setSelectedCourse={setSelectedCourse}
            allCourses={<AllCourses />}
          />
        );
      case "9":
        return (
          <LCVTrainingDetails
            setSelectedCourse={setSelectedCourse}
            allCourses={<AllCourses />}
          />
        );
      case "10":
        return (
          <LogBook
            setSelectedCourse={setSelectedCourse}
            allCourses={<AllCourses />}
          />
        );
      default:
        return (
          <AirBrakes
            setSelectedCourse={setSelectedCourse}
            allCourses={<AllCourses />}
          />
        );
    }
  };

  useEffect(() => {
    setSelectedCourse(id);
  }, [id]);

  return (
    <>
      <div id="section-subheader">
        <div
          className="sigma_subheader style-8 bg-cover bg-center dark-overlay"
          style={{
            backgroundImage: "url(assets/img/services/1920x803.jpg)",
          }}
        >
          <div className="container">
            <div className="sigma_subheader-inner">
              <h1 className="sigma_stroke-text">Course Info</h1>
            </div>
          </div>
        </div>
      </div>
      <div className="course-details" ref={courseRef}>
        {getComponent(selectedCourse)}
      </div>
      <div id="section-to-top">
        <div className="sigma_top">
          <i className="far fa-arrow-up"></i>
        </div>
      </div>
    </>
  );
}

export default CourseDetails;
