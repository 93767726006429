import { useEffect } from "react";
import { Link } from "react-router-dom";

function LCVTraining() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return(
    <>
    <div id="section-subheader">
      <div className="sigma_subheader style-8 bg-cover bg-center dark-overlay"
      style={{ backgroundImage: 'url(assets/img/finance/1920x803.jpg)' }}>
        <div className="container">
          <div className="sigma_subheader-inner">
            <h1 className="sigma_stroke-text">LCV Training</h1>
          </div>
        </div>
      </div>
    </div>
    <div id="section-lcvtraining">
      <div className="section section-padding">
        <div className="container">
          <div className="section-title centered has-element">
            <h3 className="title">LCV Training</h3>
            <div className="d-flex align-items-center justify-content-center">
              <span className="lines"></span>
              <span className="circle"></span>
              <span className="lines"></span>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-8">
              <div className="sigma_pricing style-3">
                <div className="sigma_pricing-top">
                  <span className="sigma_pricing-rate">Long Combination Vehicle (LCV) Program</span>
                  <h5 className="m-0">Ready to Expand Your Driving Skills?</h5>
                </div>
                <div className="sigma_general-list style-3">
                  <p>At AG Driving Education, we are excited to offer our specialized Long Combination Vehicle (LCV) Program. This program is designed for experienced drivers looking to enhance their qualifications and tackle the challenges of operating long combination vehicles.</p>
                  <h5>Why Choose Our LCV Program?</h5>
                  <ul>
                    <li>
                      <p><b>Expert Training: </b>Our LCV Program provides comprehensive training on handling long combination vehicles, including double and triple trailers. Learn advanced driving techniques and gain the skills needed to operate these complex vehicle configurations safely and efficiently.</p>
                    </li>
                    <li>
                      <p><b>Hands-On Experience: </b>Benefit from practical, hands-on training with real LCVs. Our experienced instructors will guide you through maneuvering, braking, and stability techniques tailored to long combination vehicles.</p>
                    </li>
                    <li>

                      <p><b>Regulatory Knowledge: </b>Stay informed about the latest regulations and safety protocols specific to LCV operations. Our program ensures you meet industry standards and legal requirements.</p>
                    </li>
                  </ul>

                  <h5>What You’ll Gain:</h5>
                  <ul>
                    <li>
                      <p><b>Advanced Skills: </b>Master the skills required to handle extended vehicle combinations, preparing you for a range of opportunities in the trucking industry.</p>
                    </li>
                    <li>
                      <p><b>Safety and Compliance: </b>Learn essential safety practices and compliance measures to operate long combination vehicles confidently and responsibly.</p>
                    </li>
                    <li>
                      <p><b>Professional Growth: </b>Achieve a valuable qualification that enhances your career prospects and opens doors to new driving opportunities.</p>
                    </li>
                  </ul>
                </div>
                <a href='/contact' className="sigma_btn">Contact Us Today</a>
                <p>For more details about our LCV Program or to enroll, reach out to us at <b>+1(780)655-4933</b>. Our team is here to assist you and help you achieve your professional goals in the trucking industry.</p>
              </div>
            </div>
            <div className="col-lg-4">
                <div className="sidebar mt-5 mt-lg-0">
                  <div className="widget widget-blockquote">
                    <blockquote className="blockquote">
                      <cite>$1,250.00</cite>
                      <p>
                        Ready to take the next step in your trucking career?{" "}
                      </p>
                      <p>Contact us to Book your next slot!</p>
                      <a href="/contact" className="sigma_btn btn-sm">
                        Book your slot
                      </a>
                    </blockquote>
                  </div>
                </div>
                <div className="widget widget-categories">
        <h5 className="widget-title">Other Courses</h5>
        <ul>
          <li>
            <Link
              to="/coursedetails?id=1"
            >
              Air Brakes
            </Link>
          </li>
          <li>
            <Link to="/coursedetails?id=2">Class 1 MELT Course</Link>
          </li>
          <li>
            <Link to="/coursedetails?id=3">
              Class 1 MELT - Equivalency and Experience
            </Link>
          </li>
          <li>
            <Link to="/coursedetails?id=4">Class 1 - Refresher</Link>
          </li>
          <li>
            <Link to="/coursedetails?id=5">
              Class 3 Drivers Training - Hourly Instruction
            </Link>
          </li>
          <li>
            <Link to="/coursedetails?id=6">
              Class 3 Drivers Training - Introductory
            </Link>
          </li>
          <li>
            <Link to="/coursedetails?id=7">Class 3 Training - Advanced</Link>
          </li>
          <li>
            <Link to="/coursedetails?id=8">Load Securement Training</Link>
          </li>
          <li>
            <Link to="/coursedetails?id=9">
              Long Combination Vehicle (LCV) Training
            </Link>
          </li>
        </ul>
      </div>
              </div>
            </div>
          </div>
        </div>
    </div>
    <div id="section-to-top">
      <div className="sigma_top">
        <i className="far fa-arrow-up"></i>
      </div>
    </div>
    </>
  );
}

export default LCVTraining;