// import emailjs from '@emailjs/browser';
import { useRef, useState } from "react";
import Lottie from "react-lottie-player";
import checkJson from "./check.json";
import sendKiteJson from "./sendKite.json";
import emailjs from "@emailjs/browser";

function Contact() {
  const form = useRef();
  const [isSending, SetIsSending] = useState(false);
  const [showForm, SetShowForm] = useState(true);

  const sendEmail = (e) => {
    e.preventDefault();
    SetShowForm(false);
    SetIsSending(true);
    emailjs
      .sendForm(
        process.env.REACT_APP_EMAIL_SERVICE_ID,
        process.env.REACT_APP_EMAIL_TEMPLATE_ID,
        form.current,
        process.env.REACT_APP_EMAIL_PUBLIC_KEY
      )
      .then(
        (result) => {
          SetIsSending(false);
        },
        (error) => {
          console.log(error.text);
        }
      );
  };

  return (
    <>
      <div id="section-subheader">
        <div
          className="sigma_subheader style-8 bg-cover bg-center dark-overlay"
          style={{ backgroundImage: "url(assets/img/contact-us/1920x800.jpg)" }}
        >
          <div className="container">
            <div className="sigma_subheader-inner">
              <h1 className="sigma_stroke-text">Contact Us</h1>
            </div>
          </div>
        </div>
      </div>
      <div id="section-contact-form">
        <div className="container-fluid p-0">
          <div className="row no-gutters">
            <div className="col-lg-6">
              <div className="sigma_contact-map has-full-height">
                <iframe
                  title="location"
                  src="https://maps.google.com/maps?width=100%25&amp;height=600&amp;hl=en&amp;q=AG DRIVING EDUCATION INC.+(AG Driving Edu)&amp;t=&amp;z=16&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
                  allowfullscreen=""
                ></iframe>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="section">
                <div className="sigma_form style-3">
                  <div className="section-title">
                    <h3 className="title">
                      Contact <span className="primary-color">Us</span>
                    </h3>
                    <p>Please fill the form below to contact us.</p>
                  </div>
                  {showForm ? (
                    <form
                      className="mf_form_validate ajax_submit"
                      ref={form}
                      method="post"
                      onSubmit={sendEmail}
                    >
                      <div className="form-group">
                        <i className="fal fa-user"></i>
                        <input
                          type="text"
                          name="user_name"
                          placeholder="Full Name"
                        />
                      </div>
                      <div className="form-group">
                        <i className="fal fa-envelope"></i>
                        <input
                          type="email"
                          name="user_email"
                          placeholder="Email"
                        />
                      </div>
                      <div className="form-group">
                        <i className="fal fa-phone"></i>
                        <input
                          type="text"
                          name="user_phone"
                          placeholder="Phone"
                        />
                      </div>
                      <div className="form-group">
                        <i className="fal fa-building"></i>
                        <input
                          type="text"
                          name="company"
                          placeholder="Company Name"
                        />
                      </div>
                      <div className="form-group">
                        <textarea
                          name="message"
                          rows="5"
                          placeholder="Leave Your Message"
                        ></textarea>
                      </div>
                      <div className="form-group">
                        <button type="submit" className="btn-block">
                          Submit
                        </button>
                        <div className="server_response w-100"></div>
                      </div>
                    </form>
                  ) : (
                    !isSending && (
                      <Lottie loop={false} animationData={checkJson} play />
                    )
                  )}
                  {isSending && (
                    <Lottie
                      loop={sendKiteJson}
                      animationData={sendKiteJson}
                      play
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Contact;
