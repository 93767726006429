import { Link } from "react-router-dom";

function Services() {
  return (
    <>
      <div id="section-services">
        <div className="section section-padding pt-0">
          <div className="container">
            <div className="section-title centered has-element">
              <h3 className="title">Courses and Fees</h3>
              <div className="d-flex align-items-center justify-content-center">
                <span className="lines"></span>
                <span className="circle"></span>
                <span className="lines"></span>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-4 col-md-6">
                <Link to="/coursedetails?id=2" className="sigma_service style-8">
                  <div className="sigma_service-thumb">
                    <img src="assets/img/services/370x240.jpg" alt="img" />
                  </div>
                  <div className="sigma_service-body">
                    <h5>Class 1 MELT Course</h5>
                    <p>
                      The Alberta Class 1 MELT (Mandatory Entry-Level Training)
                      Program is a comprehensive training and certification
                      course designed to prepare individuals for a career in
                      commercial trucking. This program is essential for those
                      aiming to obtain a Class 1 driver’s license, which is
                      required for operating large trucks and transport vehicles
                      in Alberta.
                    </p>
                    <div className="sig_courses_pricing">
                      <div className="sigma_pricing-top">
                        <span className="sigma_pricing-title">
                          <b>$9,500.00</b>
                        </span>
                      </div>
                      <div className="sigma_pricing-top">
                        <span className="sigma_pricing-title">
                          <b>121.5 Hours</b>
                        </span>
                      </div>
                    </div>
                  </div>
                </Link>
              </div>
              <div className="col-lg-4 col-md-6">
                <Link to="/coursedetails?id=3" className="sigma_service style-8">
                  <div className="sigma_service-thumb">
                    <img src="assets/img/services/370x240-0.jpg" alt="img" />
                  </div>
                  <div className="sigma_service-body">
                    <h5>Class 1 MELT - Equivalency and Experience</h5>
                    <p>
                      The Government of Alberta enacted Mandatory Entry-Level
                      Training (MELT) on March 1, 2019 to avoid any accidents by
                      requiring Class 1 (tractor-trailer) operators to get
                      proper training before being evaluated for a Class 1
                      driver license.
                    </p>
                    <p>
                      Class 2 or 3 motorists with atleast two (2) years of
                      experience operating Class 2 or 3 commercial vehicles with
                      air brakes may be qualified to participate in the
                      Experience and Equivalency Class 1 MELT Program.
                    </p>
                    <div className="sig_courses_pricing">
                      <div className="sigma_pricing-top">
                        <span className="sigma_pricing-title">
                          <b>$4,000.00</b>
                        </span>
                      </div>
                      <div className="sigma_pricing-top">
                        <span className="sigma_pricing-title">
                          <b>40 Hours</b>
                        </span>
                      </div>
                    </div>
                  </div>
                </Link>
              </div>
              <div className="col-lg-4 col-md-6">
                <Link to="/coursedetails?id=4" className="sigma_service style-8">
                  <div className="sigma_service-thumb">
                    <img src="assets/img/services/370x241.jpg" alt="img" />
                  </div>
                  <div className="sigma_service-body">
                    <h5>Class 1 - Refresher</h5>
                    <p>
                      Individuals with prior commercial driving experience may
                      be eligible for certain exemptions or reduced training
                      requirements. This typically applies to those who have
                      extensive experience operating large vehicles or who have
                      previously held a similar license in another jurisdiction.
                    </p>
                    <div className="sig_courses_pricing">
                      <div className="sigma_pricing-top">
                        <span className="sigma_pricing-title">
                          <b>$120.00</b>
                        </span>
                      </div>
                      <div className="sigma_pricing-top">
                        <span className="sigma_pricing-title">
                          <b>Per Hour</b>
                        </span>
                      </div>
                    </div>
                  </div>
                </Link>
              </div>
              <div className="col-lg-4 col-md-6">
                <Link to="/coursedetails?id=5" className="sigma_service style-8">
                  <div className="sigma_service-thumb">
                    <img src="assets/img/services/370x240-1.jpg" alt="img" />
                  </div>
                  <div className="sigma_service-body">
                    <h5>Class 3 Drivers Training - Hourly Instruction</h5>
                    <p>
                      Class 3 Drivers Training is a specialized program designed
                      for individuals who wish to obtain a Class 3 driver’s
                      license.
                      
                      <p>By opting for hourly instruction, you can receive focused, flexible training that meets your specific needs and helps you prepare effectively for obtaining your Class 3 license.</p>
                      <p>This license allows the holder to operate medium-sized trucks, including those configured with three axles and have a GVWR of over 11,794 kg (26,000 lbs).</p>
                    </p>
                    <div className="sig_courses_pricing">
                      <div className="sigma_pricing-top">
                        <span className="sigma_pricing-title">
                          <b>$120.00</b>
                        </span>
                      </div>
                      <div className="sigma_pricing-top">
                        <span className="sigma_pricing-title">
                          <b>Per Hour</b>
                        </span>
                      </div>
                    </div>
                  </div>
                </Link>
              </div>
              <div className="col-lg-4 col-md-6">
                <Link to="/coursedetails?id=6" className="sigma_service style-8">
                  <div className="sigma_service-thumb">
                    <img src="assets/img/services/1920x803.jpg" alt="img" />
                  </div>
                  <div className="sigma_service-body">
                    <h5>Class 3 Drivers Training - Introductory</h5>
                    <p>
                    An introductory Class 3 drivers training program is designed for individuals who are new to operating medium-duty trucks and need to start with the basics. This type of training provides a foundational understanding of vehicle operation and prepares you for the more advanced aspects of driving a Class 3 vehicle.
                    </p>
                    <p><b>Includes Air Brakes.</b></p>
                    <div className="sig_courses_pricing">
                      <div className="sigma_pricing-top">
                        <span className="sigma_pricing-title">
                          <b>$1,500.00</b>
                        </span>
                      </div>
                      <div className="sigma_pricing-top">
                        <span className="sigma_pricing-title">
                          <b>18.5 Hours</b>
                        </span>
                      </div>
                    </div>
                  </div>
                </Link>
              </div>
              <div className="col-lg-4 col-md-6">
                <Link to="/coursedetails?id=7" className="sigma_service style-8">
                  <div className="sigma_service-thumb">
                    <img src="assets/img/services/370x240-111.jpg" alt="img" />
                  </div>
                  <div className="sigma_service-body">
                    <h5>Class 3 Training - Advanced</h5>
                    <p>
                      Advanced Class 3 driver training is designed for those who have mastered the basics of operating a medium-duty truck and wish to refine their skills further. This level of training focuses on more complex driving scenarios, advanced vehicle handling, and in-depth regulatory compliance.
                    </p>
                    <p><b>Includes Air Brakes and Load Securement.</b></p>
                    <div className="sig_courses_pricing">
                      <div className="sigma_pricing-top">
                        <span className="sigma_pricing-title">
                          <b>$3,350.00</b>
                        </span>
                      </div>
                      <div className="sigma_pricing-top">
                        <span className="sigma_pricing-title">
                          <b>30.5 Hours</b>
                        </span>
                      </div>
                    </div>
                  </div>
                </Link>
              </div>
              <div className="col-lg-4 col-md-6">
                <Link to="/coursedetails?id=1" className="sigma_service style-8">
                  <div className="sigma_service-thumb">
                    <img src="assets/img/services/370x240-11.jpg" alt="img" />
                  </div>
                  <div className="sigma_service-body">
                    <h5>Air Brakes Certification</h5>
                    <p>
                      The Alberta Air Brakes Course is a specialized training
                      program designed to provide drivers with the knowledge and
                      skills required to safely operate vehicles equipped with
                      air brake systems. Air brakes are commonly used in large
                      trucks, buses, and other heavy vehicles, making this
                      course a crucial step for drivers seeking to enhance their
                      qualifications and ensure road safety.
                    </p>
                    <div className="sig_courses_pricing">
                      <div className="sigma_pricing-top">
                        <span className="sigma_pricing-title">
                          <b>$250.00</b>
                        </span>
                      </div>
                      <div className="sigma_pricing-top">
                        <span className="sigma_pricing-title">
                          <b>1 Day</b>
                        </span>
                      </div>
                    </div>
                  </div>
                </Link>
              </div>
              <div className="col-lg-4 col-md-6">
                <Link to="/coursedetails?id=8" className="sigma_service style-8">
                  <div className="sigma_service-thumb">
                    <img src="assets/img/services/370x240-12.jpg" alt="img" />
                  </div>
                  <div className="sigma_service-body">
                    <h5>Load Securement Training</h5>
                    <p>
                      Load Securement Training is a critical component of
                      commercial vehicle operations, designed to ensure that
                      cargo is transported safely and in compliance with
                      regulatory standards. Proper load securement is essential
                      for preventing accidents, protecting cargo, and
                      maintaining overall road safety.
                    </p>
                    <div className="sig_courses_pricing">
                      <div className="sigma_pricing-top">
                        <span className="sigma_pricing-title">
                          <b>$120.00</b>
                        </span>
                      </div>
                      <div className="sigma_pricing-top">
                        {/* <span className="sigma_pricing-title"><b>4 Hours</b></span> */}
                      </div>
                    </div>
                  </div>
                </Link>
              </div>
              <div className="col-lg-4 col-md-6">
                <Link to="/coursedetails?id=9" className="sigma_service style-8">
                  <div className="sigma_service-thumb">
                    <img src="assets/img/services/550x400.jpg" alt="img" />
                  </div>
                  <div className="sigma_service-body">
                    <h5>Long Combination Vehicle (LCV) Training</h5>
                    <p>
                      At AG Driving Education, we are excited to offer our
                      specialized Long Combination Vehicle (LCV) Program. This
                      program is designed for experienced drivers looking to
                      enhance their qualifications and tackle the challenges of
                      operating long combination vehicles.
                    </p>
                    <div className="sig_courses_pricing">
                      <div className="sigma_pricing-top">
                        <span className="sigma_pricing-title">
                          <b>$1,250.00</b>
                        </span>
                      </div>
                      <div className="sigma_pricing-top">
                        <span className="sigma_pricing-title">
                          <b></b>
                        </span>
                      </div>
                    </div>
                  </div>
                </Link>
              </div>
              <div className="col-lg-4 col-md-6">
                <Link to="/coursedetails?id=10" className="sigma_service style-8">
                  <div className="sigma_service-thumb">
                    <img src="assets/img/services/Truck_driver_log.jpg" alt="img" />
                  </div>
                  <div className="sigma_service-body">
                    <h5>Log Book/Hour of Service</h5>
                    <p>Maintaining accurate log books and adhering to Hours of Service regulations are essential for ensuring safety on the roads and compliance with legal requirements. Proper record-keeping helps manage driver fatigue and contributes to overall road safety.</p>
                    <div className="sig_courses_pricing">
                      <div className="sigma_pricing-top">
                        <span className="sigma_pricing-title">
                          <b>$120.00</b>
                        </span>
                      </div>
                      <div className="sigma_pricing-top">
                        <span className="sigma_pricing-title">
                          <b>4 Hours</b>
                        </span>
                      </div>
                    </div>
                  </div>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Services;
