// eslint-disable-next-line react/prop-types
function Class3Hourly({ allCourses }) {
  return (
    <>
      <div id="section-service-details">
        <div className="section sigma_post-details">
          <div className="container">
            <div className="row">
              <div className="col-lg-8">
                <div className="sigma_post-details-inner">
                  <div className="entry-content">
                    <div className="row">
                      {/* <div className="col-md-4">
                    <a href="assets/img/service-details/240.jpg" className="gallery-thumb">
                      <img src="assets/img/service-details/240.jpg" alt="img"/>
                    </a>
                  </div>
                  <div className="col-md-4">
                    <a href="assets/img/service-details/240-0.jpg" className="gallery-thumb">
                      <img src="assets/img/service-details/240-0.jpg" alt="post"/>
                    </a>
                  </div>
                  <div className="col-md-4">
                    <a href="assets/img/service-details/240-1.jpg" className="gallery-thumb">
                      <img src="assets/img/service-details/240-1.jpg" alt="post"/>
                    </a>
                  </div> */}
                    </div>
                    <h2 className="entry-title">
                      Class 3 Drivers Training - Hourly Instruction
                    </h2>
                    <p>
                      Class 3 Drivers Training is a specialized program designed
                      for individuals who wish to obtain a Class 3 driver’s
                      license.
                      
                      <p>By opting for hourly instruction, you can receive focused, flexible training that meets your specific needs and helps you prepare effectively for obtaining your Class 3 license.</p>
                      <p>This license allows the holder to operate medium-sized trucks, including those configured with three axles and have a GVWR of over 11,794 kg (26,000 lbs).</p>
                    </p>

                    <img src="assets/img/services/370x240-1.jpg" alt="post" />
                    {/* <h4>When You Start Learning To Drive, It Can Be A Little Tuff</h4>
                <p>
                  New drivers the theory and techniques needed to carefully operate various types of vehicles such as cars, motorcycles, trucks and buses. A driving instructor has several names and can be recognized as Auto Driving Instructor, Educator, Instructor, Teacher or Truck Driving Instructor Teenager Danny wanted to pass his test so he could visit his girlfriend in Preston Although he passed first time, he had already.
                </p>
                <hr/>
                <h4>Take Driving lessons with a private tutor</h4>
                <p>
                  The duties and responsibilities of a driving instructor are to manage the theory className timings, teach the driving students about the right traffic rules and inform student drivers with safety driving procedures. In addition to that, driving instructors supply their students with information about emergency situations After the classNamees about theory knowledge, driving instructors help their driving students in practically learning how to drive a car individually. At the beginning, the driving instructor teaches the student how to use a steering wheel, how to reverse and how to park In addition, the driving instructor.
                </p> */}
                  </div>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="sidebar mt-5 mt-lg-0">
                  <div className="widget widget-blockquote">
                    <blockquote className="blockquote">
                      <cite>$120.00</cite>
                      <cite>Per Hour</cite>
                      <p>
                        Ready to take the next step in your trucking career?{" "}
                      </p>
                      <p>Contact us to Book your next slot!</p>
                      <a href="/contact" className="sigma_btn btn-sm">
                        Book your slot
                      </a>
                    </blockquote>
                  </div>
                  {allCourses}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="section-to-top">
        <div className="sigma_top">
          <i className="far fa-arrow-up"></i>
        </div>
      </div>
    </>
  );
}

export default Class3Hourly;
