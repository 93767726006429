// src/components/GoogleReviews.js
import React, { useState, useEffect } from "react";
import { Rating, ThinStar } from "@smastrom/react-rating";

// eslint-disable-next-line import/no-unresolved
import "@smastrom/react-rating/style.css";
import Slider from "react-slick";

const myStyles = {
  itemShapes: ThinStar,
  activeFillColor: "#ffb700",
  inactiveFillColor: "#fbf1a9",
};

// eslint-disable-next-line react/prop-types
function Testimonial({ placeId }) {
  const [reviews, setReviews] = useState([]);
  const [error, setError] = useState(null);
  const [totalReviews, setTotalReviews] = useState(null);
  const [rating, setRating] = useState(null);

  useEffect(() => {
    const fetchReviews = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}google/reviews/ChIJLUOhHUQZoFMR-7NhoEe4IZI`
        );

        const myReviews = [
          {
            author_name: "Jarrett Jackson",
            author_url: "https://www.google.com/maps/contrib/100538346678168103913/reviews",
            profile_photo_url: "https://lh3.googleusercontent.com/a-/ALV-UjW-CaPQLLZha4bLa9aqGcRcJA2I-PrphndcZ7zgW37jGgtdSzMJ=s128-c0x00000000-cc-rp-mo",
            rating: 5,
            text: "Great set of instructors, enjoyable atmosphere, convenient location, nice office/classroom. Would recommend for the class 1 program.",
          },
          {
            author_name: "Steven Kurowski",
            author_url: "https://www.google.com/maps/contrib/103471063260781379002/reviews",
            profile_photo_url: "https://lh3.googleusercontent.com/a-/ALV-UjVkrVXHyl_gbUql6yvkGygPssheMIs9Ji-ORAR2Fpw4nGCIa_wkRQ=s128-c0x00000000-cc-rp-mo",
            rating: 5,
            text: "Learning to drive a class one vehicle is not easy, but AG Driving Education Inc. made the process easy and achievable at every step. Their amazing instructors made all the driving inspection and processes clear and easy to perform on the road in real life as well as on the test. I felt fully prepared and confident to pass the test on the day, and when I succeeded, they celebrated my success positively. I am grateful for all the care and attention that every instructor has provided me.  If I had to choose again, AG Driving Education Inc. would be my immediate first choice. Thank you again, to all the instructors and staff at AG Driving Education Inc.",
          },
          {
            author_name: "Abdourahman Ahmed",
            author_url: "https://www.google.com/maps/contrib/114072158317501889247/reviews",
            profile_photo_url: "https://lh3.googleusercontent.com/a/ACg8ocJxbW67zxSP_p0pj3kNYrFe-l-IE0hmbsHgnCrnCu5zQr8pLw=s128-c0x00000000-cc-rp-mo",
            rating: 5,
            text: "I started Ag driving school with 0 knowledge driving manual transmission but for the help of Allah and the instructors( Paramjit,Nav and Aman)I learned how to shift a manual transmission with the accelerator and matching RPM.I got my licence for the first attempt I’m very grateful to attend AG driving school also Bani the office lady is flexible on your schedule whenever I was asking her any help she was helpful and did her best to get what you requested.I really really appreciate the way Navpreet taught me everything concerning yard work and driving on the road.Thank you all of you specially Navpreet.",
          },
          {
            author_name: "balamurugan ramakrishnan",
            author_url: "https://www.google.com/maps/contrib/105204202342048072565/reviews",
            profile_photo_url: "https://lh3.googleusercontent.com/a/ACg8ocLRfTWY5fy98yTbbeplYUBowGQyv4X9eCVkmJIz2Ucv6WBxaw=s128-c0x00000000-cc-rp-mo",
            rating: 5,
            text: "I just got my class 1 license from AG driving school, thanks to all management of AG driving school.And special thanks to the instructor(owner) PARMJIT SINGH he best teacher and other instructor NAVPREET SINGH he also very best teacher, and I got knowledge and information and experience it’s one of the best times. if any one wants take class 1 lincence my recommendation and best choice is the AG DRIVING EDUCATION. And special thanks to banni from office she best and very helpful for flexible schedules and kindness  thanks for  AG DRIVING SCHOOL.",
          }
        ];

        const data = await response.json();
        if (data.status === "OK") {
          setReviews([...myReviews, ...data.result.reviews] || []);
          setTotalReviews(data.result.user_ratings_total);
          setRating(data.result.rating);
        } else {
          setError(`Error: ${data.status}`);
        }
      } catch (err) {
        setError("Error fetching reviews.");
      }
    };

    fetchReviews();
  }, [placeId]);

  if (error) {
    return <div>Error loading reviews.</div>;
  }

  const settings = {
    dots: true,
    infinite: true,
    slidesToShow: 2,
    slidesToScroll: 1,
    autoplay: true,
    speed: 2000,
    autoplaySpeed: 2000,
    cssEase: "linear",
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <>
      <div id="section-testimonial">
        <div
          className="section section-padding bg-cover bg-center light-overlay"
          style={{ backgroundImage: "url(assets/img/home-2/1920x800-1.jpg)" }}
        >
          <div className="container">
            <div className="section-title centered has-element">
              <h3 className="title">Our Testimonials</h3>
              <div className="d-flex align-items-center justify-content-center">
                <span className="lines"></span>
                <span className="circle"></span>
                <span className="lines"></span>
              </div>
            </div>
            <div className="d-flex align-items-center justify-content-center">
              <Rating
                itemStyles={myStyles}
                style={{ maxWidth: 100 }}
                value={5}
                readOnly
              />
            </div>
            <h3 className="d-flex align-items-center justify-content-center">
              {rating} stars based on {totalReviews} Google reviews
            </h3>
            <div>
              <Slider {...settings} pauseOnHover dots={false}>
                {reviews.length > 0 ? (
                  reviews.map((review, i) => (
                    <div className="col-lg-12" key={i}>
                      <div
                        className="sigma_testimonial style-11"
                        // style={{ width: "27rem" }}
                      >
                        <div className="sigma_testimonial-body">
                          <div className="d-flex align-items-center">
                            <div className="sigma_testimonial-thumb">
                              <img
                                src={review.profile_photo_url}
                                alt="testimonial"
                              />
                            </div>
                            <div className="sigma_author-block">
                              <h5>{review.author_name}</h5>
                              <span className="sigma_testimonial-category">
                                <Rating
                                  itemStyles={myStyles}
                                  style={{ maxWidth: 100 }}
                                  value={review.rating}
                                  readOnly
                                />
                              </span>
                            </div>
                          </div>
                          <div className="d-block d-sm-flex mt-4">
                            <span className="fas fa-quote-left sigma_testimonial-icon"></span>
                            <p className="mt-sm-0 mb-0 mt-3 truncate-text">
                              {review.text}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))
                ) : (
                  <p>No Data</p>
                )}
              </Slider>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Testimonial;
