// eslint-disable-next-line react/prop-types
function LogBook({ allCourses }) {
  return (
    <>
      <div id="section-service-details">
        <div className="section sigma_post-details">
          <div className="container">
            <div className="row">
              <div className="col-lg-8">
                <div className="sigma_post-details-inner">
                  <div className="entry-content">
                    <div className="row">
                    </div>
                    <h2 className="entry-title">Log Book/Hour of Service</h2>
                    <p>
                    Maintaining accurate log books and adhering to Hours of Service regulations are essential for ensuring safety on the roads and compliance with legal requirements. Proper record-keeping helps manage driver fatigue and contributes to overall road safety.
                    </p>
                    <img src="assets/img/services/Truck_driver_log.jpg" alt="post" />
                  </div>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="sidebar mt-5 mt-lg-0">
                  <div className="widget widget-blockquote">
                    <blockquote className="blockquote">
                      <cite>$120.00</cite>
                      <cite>4 Hours</cite>
                      <p>
                        Ready to take the next step in your trucking career?{" "}
                      </p>
                      <p>Contact us to Book your next slot!</p>
                      <a href="/contact" className="sigma_btn btn-sm">
                        Book your slot
                      </a>
                    </blockquote>
                  </div>
                  {allCourses}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="section-to-top">
        <div className="sigma_top">
          <i className="far fa-arrow-up"></i>
        </div>
      </div>
    </>
  );
}

export default LogBook;
