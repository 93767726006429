/* eslint-disable jsx-a11y/anchor-is-valid */
function Team() {
  return (
    <>
      <div id="section-team">
        <div className="section section-padding">
          <div className="container">
            <div className="section-title centered has-element">
              <h3 className="title">Our Instructors</h3>
              <div className="d-flex align-items-center justify-content-center">
                <span className="lines"></span>
                <span className="circle"></span>
                <span className="lines"></span>
              </div>
            </div>
            
            <div className="sigma_team style-10">
                    <div className="sigma_team-body">
                      <p>
                      With over 15 years of experience in the trucking industry, our instructors bring a wealth of knowledge, expertise, and dedication to our training programs. Their extensive backgrounds encompass a broad range of roles and responsibilities within the industry, including long-haul driving, fleet management, and safety compliance. This diverse experience equips them with a comprehensive understanding of both the operational and regulatory aspects of trucking.
                      </p><br/>

                      <p>
                        Our instructors are highly skilled in delivering the Class 1 MELT program, Air Brake Systems and Commercial Vehicle Inspection which is crucial for new truck drivers in Alberta. They possess an in-depth understanding of the curriculum and its requirements, ensuring that students are thoroughly prepared for their written and practical examinations. Their teaching methodology integrates the latest industry practices and regulatory standards, providing students with a robust foundation for their driving careers.
                      </p>
                      {/* <h6>Expertise and Specialization:</h6>
                      <p>
                      <b>Class 1 MELT (Mandatory Entry-Level Training): </b>Our instructors are highly skilled in delivering the Class 1 MELT program, which is crucial for new truck drivers in Alberta. They possess an in-depth understanding of the curriculum and its requirements, ensuring that students are thoroughly prepared for their written and practical examinations. Their teaching methodology integrates the latest industry practices and regulatory standards, providing students with a robust foundation for their driving careers.
                      </p>
                      <p>
                        <b>Air Brake Systems: </b>With specialized training in air brake systems, our instructors offer expert guidance on the operation, maintenance, and troubleshooting of air brakes. Their practical knowledge extends to the intricacies of air brake technology, including compressor functions, reservoir management, and brake adjustments. This expertise is crucial for ensuring that students can handle air brake systems safely and effectively, a key requirement for operating heavy commercial vehicles.
                      </p>
                      <p>
                        <b>Commercial Vehicle Inspection: </b>Our instructors are adept at teaching the principles of commercial vehicle inspection, focusing on thorough pre-trip and routine inspections. They emphasize the importance of vehicle safety and compliance with Alberta’s inspection standards. By imparting detailed knowledge on inspecting mechanical systems, electrical components, and safety equipment, our instructors ensure that students are proficient in identifying and addressing potential issues before they impact road safety.
                      </p> */}
                      {/* <ul className="sigma_social-icons has-border">
                        <li>
                          <a href="#">
                            <i className="fab fa-facebook-f"></i>
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <i className="fab fa-instagram"></i>
                          </a>
                        </li>
                      </ul> */}
                    </div>
                  </div>
            
            {/* <div className="row align-items-center">
              <div className="col-lg-6 tab-content" id="myTabContent">
                <div
                  className="tab-pane fade show active"
                  id="member-1"
                  role="tabpanel"
                  aria-labelledby="member-1-tab"
                >
                  <div className="sigma_team style-10">
                    <div className="sigma_team-body">
                      <h5>
                        <a href="#">Paramjit Singh</a>
                      </h5>
                      <div className="sigma_team-categories">
                        <a href="#" className="sigma_team-category">
													Senior Instructor
                        </a>
                      </div>
                      <p>
                      Paramjit Singh is a distinguished member of our team at AG Truck Driving Education Inc. With over 15 years of experience in the trucking industry, Paramjit Singh brings a wealth of knowledge, expertise, and dedication to our training programs. His commitment to excellence and passion for teaching make him an invaluable asset to our organization.
                      </p>
                      <p>
                      Paramjit specializes in Class 1 MELT (Mandatory Entry-Level Training), air brake systems, and commercial vehicle inspection. His deep knowledge of these areas ensures that students receive high-quality instruction and practical insights into the complexities of modern trucking.
                      </p>
                      <ul className="sigma_social-icons has-border">
                        <li>
                          <a href="#">
                            <i className="fab fa-facebook-f"></i>
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <i className="fab fa-instagram"></i>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div
                  className="tab-pane fade"
                  id="member-2"
                  role="tabpanel"
                  aria-labelledby="member-2-tab"
                >
                  <div className="sigma_team style-10">
                    <div className="sigma_team-body">
                      <h5>
                        <a href="#">John Doe</a>
                      </h5>
                      <div className="sigma_team-categories">
                        <a href="#" className="sigma_team-category">
													CEO
                        </a>
                      </div>
                      <p>
												Driving School is a docusoap that was broadcast on BBC1
												in the summer of 1997, which followed a group of learner
												drivers around Bristol and South Wales. The series was
												made on a reduced budget but shown in primetime, it
												created one of the first reality TV show.
                      </p>
                      <p>
												A driving instructor is a person who is hired by a new
												driver who is learning how to improve their skills -
												often for an upcoming.
                      </p>
                      <ul className="sigma_social-icons has-border">
                        <li>
                          <a href="#">
                            <i className="fab fa-facebook-f"></i>
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <i className="fab fa-instagram"></i>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div
                  className="tab-pane fade"
                  id="member-3"
                  role="tabpanel"
                  aria-labelledby="member-3-tab"
                >
                  <div className="sigma_team style-10">
                    <div className="sigma_team-body">
                      <h5>
                        <a href="#">Jack Mario</a>
                      </h5>
                      <div className="sigma_team-categories">
                        <a href="#" className="sigma_team-category">
													Executive
                        </a>
                      </div>
                      <p>
												Driving School is a docusoap that was broadcast on BBC1
												in the summer of 1997, which followed a group of learner
												drivers around Bristol and South Wales. The series was
												made on a reduced budget but shown in primetime, it
												created one of the first reality TV show.
                      </p>
                      <p>
												A driving instructor is a person who is hired by a new
												driver who is learning how to improve their skills -
												often for an upcoming.
                      </p>
                      <ul className="sigma_social-icons has-border">
                        <li>
                          <a href="#">
                            <i className="fab fa-facebook-f"></i>
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <i className="fab fa-instagram"></i>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div
                  className="tab-pane fade"
                  id="member-4"
                  role="tabpanel"
                  aria-labelledby="member-4-tab"
                >
                  <div className="sigma_team style-10">
                    <div className="sigma_team-body">
                      <h5>
                        <a href="#">Tim Abell</a>
                      </h5>
                      <div className="sigma_team-categories">
                        <a href="#" className="sigma_team-category">
													CEO
                        </a>
                      </div>
                      <p>
												Driving School is a docusoap that was broadcast on BBC1
												in the summer of 1997, which followed a group of learner
												drivers around Bristol and South Wales. The series was
												made on a reduced budget but shown in primetime, it
												created one of the first reality TV show.
                      </p>
                      <p>
												A driving instructor is a person who is hired by a new
												driver who is learning how to improve their skills -
												often for an upcoming.
                      </p>
                      <ul className="sigma_social-icons has-border">
                        <li>
                          <a href="#">
                            <i className="fab fa-facebook-f"></i>
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <i className="fab fa-instagram"></i>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <ul
                  className="row nav nav-tabs nav-tabs-nulled"
                  id="myTab"
                  role="tablist"
                >
                  <li className="col-md-6 nav-item">
                    <a
                      className="nav-link active"
                      id="member-1-tab"
                      data-toggle="tab"
                      href="#member-1"
                      role="tab"
                      aria-controls="member-1"
                      aria-selected="true"
                    >
                      <div className="sigma_team">
                        <div className="sigma_team-thumb has-width">
                          <img
                            src="assets/img/home-2/120x120.jpg"
                            className="rounded-circle"
                            alt="team"
                          />
                        </div>
                        <div className="sigma_team-body">
                          <h5>Paramjit Singh</h5>
                          <div className="sigma_team-categories">
                            <span className="sigma_team-category">Senior Instructor</span>
                          </div>
                        </div>
                      </div>
                    </a>
                  </li>
                  <li className="col-md-6 nav-item">
                    <a
                      className="nav-link"
                      id="member-2-tab"
                      data-toggle="tab"
                      href="#member-2"
                      role="tab"
                      aria-controls="member-2"
                      aria-selected="false"
                    >
                      <div className="sigma_team">
                        <div className="sigma_team-thumb has-width">
                          <img
                            src="assets/img/home-2/120x120-0.jpg"
                            className="rounded-circle"
                            alt="team"
                          />
                        </div>
                        <div className="sigma_team-body">
                          <h5>John Doe</h5>
                          <div className="sigma_team-categories">
                            <span className="sigma_team-category">Ceo</span>
                          </div>
                        </div>
                      </div>
                    </a>
                  </li>
                  <li className="col-md-6 nav-item">
                    <a
                      className="nav-link"
                      id="member-3-tab"
                      data-toggle="tab"
                      href="#member-3"
                      role="tab"
                      aria-controls="member-3"
                      aria-selected="false"
                    >
                      <div className="sigma_team">
                        <div className="sigma_team-thumb has-width">
                          <img
                            src="assets/img/home-2/120x120-1.jpg"
                            className="rounded-circle"
                            alt="team"
                          />
                        </div>
                        <div className="sigma_team-body">
                          <h5>Jack Mario</h5>
                          <div className="sigma_team-categories">
                            <span className="sigma_team-category">Manager</span>
                          </div>
                        </div>
                      </div>
                    </a>
                  </li>
                  <li className="col-md-6 nav-item">
                    <a
                      className="nav-link"
                      id="member-4-tab"
                      data-toggle="tab"
                      href="#member-4"
                      role="tab"
                      aria-controls="member-4"
                      aria-selected="false"
                    >
                      <div className="sigma_team">
                        <div className="sigma_team-thumb has-width">
                          <img
                            src="assets/img/home-2/120x120-2.jpg"
                            className="rounded-circle"
                            alt="team"
                          />
                        </div>
                        <div className="sigma_team-body">
                          <h5>Tim Abell</h5>
                          <div className="sigma_team-categories">
                            <span className="sigma_team-category">
															Instructor
                            </span>
                          </div>
                        </div>
                      </div>
                    </a>
                  </li>
                </ul>
              </div>
            </div> */}
          </div>
        </div>
      </div>
    </>
  );
}

export default Team;
