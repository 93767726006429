import { useEffect } from "react";
import Services from "../Home/services";

function Courses() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <div id="section-subheader">
        <div
          className="sigma_subheader style-8 bg-cover bg-center dark-overlay"
          style={{ backgroundImage: "url(assets/img/finance/1920x802.jpg)" }}
        >
          <div className="container">
            <div className="sigma_subheader-inner">
              <h1 className="sigma_stroke-text">Courses and Fees</h1>
            </div>
          </div>
        </div>
      </div>
      <div id="section-pricing">
        <div className="section section-padding">
          <div className="container">
            <div>
              <Services />
            </div>
          </div>
        </div>
      </div>
      <div id="section-to-top">
        <div className="sigma_top">
          <i className="far fa-arrow-up"></i>
        </div>
      </div>
    </>
  );
}

export default Courses;
