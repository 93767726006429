function Features() {
  return (
    <>
      <div id="section-feature">
        <div className="section section-padding pt-0">
          <div className="container">
            <div className="section-title centered has-element">
              <h3 className="title">Why Choose Us?</h3>
              <div className="d-flex align-items-center justify-content-center">
                <span className="lines"></span>
                <span className="circle"></span>
                <span className="lines"></span>
              </div>
            </div>
            <div className="row align-items-center">
              <div className="col-lg-6">
                <div className="sigma_info-image mb-30">
                  <img
                    src="assets/img/home-2/540x500.jpg"
                    className="w-100"
                    alt="img"
                  />
                </div>
              </div>
              <div className="col-lg-6">
                <div className="row">
                  <div className="col-md-6">
                    <div className="sigma_info style-12">
                      <div className="sigma_info-title">
                        <span className="sigma_info-icon bg-primary-light primary-color">
                          <i className="flaticon-instructor is_flaticon"></i>
                        </span>
                        <h5>Expert Instructors</h5>
                      </div>
                      <div className="sigma_info-description">
                        <p>
                        Our team of expert instructors brings more than a decade of experience to our training programs, providing unparalleled expertise and insight into their respective fields.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="sigma_info style-12">
                      <div className="sigma_info-title">
                        <span className="sigma_info-icon bg-primary-light primary-color">
                          <i className="flaticon-award-1 is_flaticon"></i>
                        </span>
                        <h5>A Wide Range Of Courses</h5>
                      </div>
                      <div className="sigma_info-description">
                        <p>
                        We proudly offer an extensive selection of courses designed to cater to a diverse array of interests and professional requirements.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="sigma_info style-12">
                      <div className="sigma_info-title">
                        <span className="sigma_info-icon bg-primary-light primary-color">
                          <i className="flaticon-24-hours-1 is_flaticon"></i>
                        </span>
                        <h5>No GST On Our Fees</h5>
                      </div>
                      <div className="sigma_info-description">
                        <p>
                        At AG Driving Education, we are committed to providing clear and straightforward pricing for all our services. We are pleased to inform you that no GST is applied to our fees.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="sigma_info style-12">
                      <div className="sigma_info-title">
                        <span className="sigma_info-icon bg-primary-light primary-color">
                          <i className="flaticon-rating is_flaticon"></i>
                        </span>
                        <h5>Satisfied Clients</h5>
                      </div>
                      <div className="sigma_info-description">
                        <p>
                        At AG Driving Education, our foremost goal is to deliver exceptional value and results to our clients. We take pride in the positive feedback and high levels of satisfaction from those who have partnered with us.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Features;
