/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect, useState } from "react";
import "./schedule.css";

function Schedule() {
  const [currentDate, setCurrentDate] = useState(() => {
    return new Date();
  });
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedDateText, setSelectedDatetext] = useState(null);
  const [selectedWeek, setSelectedWeek] = useState(null);
  const [selectedSchedule, setSelectedSchedule] = useState([]);
  const [schedule, setSchedule] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchQuestions = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}schedule.json`
        );
        const { data, status } = await response.json();
        if (status === "OK" && data.length > 0) {
          setSchedule(data || []);
        } else {
          setError(`Error: ${status}`);
        }
        setLoading(false);
      } catch (err) {
        setError("Error fetching questions.");
        setLoading(false);
      }
    };
    fetchQuestions();
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (selectedDate) {
      setSelectedSchedule(
        schedule.filter(
          (s) =>
            new Date(s.id).toLocaleDateString() ===
            selectedDate.toLocaleDateString()
        )
      );
    }
  }, [selectedDate, schedule]);

  const currentMonthIndex = currentDate.getMonth();

  // get the number of days in the current month
  const daysInMonth = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth() + 1,
    0
  ).getDate();

  // get the day of the week for the first day of the current month (0 = Sun, 1 = Mon, ..., 6 = Sat)
  const firstDayOfWeek = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth(),
    1
  ).getDay();

  // get the current year and month from the current date
  const currentYearMonth = `${currentDate.getFullYear()} ${currentDate.toLocaleString(
    "en-US",
    {
      month: "long",
    }
  )}`;

  function handleDateChange(increment) {
    const newDate = new Date(currentDate);
    newDate.setMonth(newDate.getMonth() + increment);
    setCurrentDate(newDate);
    setSelectedWeek(null);
    setSelectedDate(null);
    setSelectedDatetext(null);
  }

  const dateBoxes = Array.from(
    { length: Math.ceil((daysInMonth + firstDayOfWeek) / 7) },
    (_, i) => i
  ).map((week, index) => (
    <div className="week" key={week} id={index}>
      {Array.from({ length: 7 }, (_, i) => i).map((day, i) => {
        const date = week * 7 + day + 1 - firstDayOfWeek;
        const actualDate = new Date();
        const actualMonth = actualDate.getMonth();
        const isActualMonth = actualMonth === currentMonthIndex;
        const actualYear = actualDate.getFullYear();
        const isActualYear = actualYear === currentDate.getFullYear();
        let dateText;
        if (date < 1) {
          dateText = "";
        } else if (date > daysInMonth) {
          dateText = "";
        } else {
          dateText = date;
        }

        const isSelected = selectedDateText
          ? selectedDateText === dateText
          : isActualMonth && isActualYear && currentDate.getDate() === date;

        const getDay = () => {
          let dayOfWeek = "Sun";
          if (day === 1) dayOfWeek = "Mon";
          if (day === 2) dayOfWeek = "Tue";
          if (day === 3) dayOfWeek = "Wed";
          if (day === 4) dayOfWeek = "Thu";
          if (day === 5) dayOfWeek = "Fri";
          if (day === 6) dayOfWeek = "Sat";
          return dayOfWeek;
        };

        const sDate = new Date(
          currentDate.getFullYear(),
          currentMonthIndex,
          date,
          actualDate.getHours(),
          actualDate.getMinutes(),
          actualDate.getSeconds()
        );

        const countOfSchedule =
          schedule && dateText !== ""
            ? schedule.find(
                (s) =>
                  new Date(s.id).toLocaleDateString() ===
                  sDate.toLocaleDateString()
              )?.events.length
            : 0;

        const dateSchedule =
          countOfSchedule > 0
            ? schedule.find(
                (s) =>
                  new Date(s.id).toLocaleDateString() ===
                  sDate.toLocaleDateString()
              )?.events
            : [];

        const dayClass =
          dateText === "" ? "day hide-date" : isSelected ? "day today" : "day";

        const hasClass =
          dateText !== "" && !countOfSchedule
            ? dayClass
            : `${dayClass} border-blue`;

        return (
          <>
            <div
              key={`${week}-${day}`}
              className={hasClass}
              onClick={() => {
                if (dateText >= 1 && dateText <= daysInMonth) {
                  setSelectedDatetext(dateText);
                  setSelectedDate(sDate);
                  setSelectedWeek(index);
                }
              }}
            >
              <div className="large-date">
                <div>{date < 1 || date > daysInMonth ? "" : getDay()}</div>
                <div>{dateText}</div>
              </div>
              <div className="day-name">
                {date < 1 || date > daysInMonth ? "" : getDay()}
              </div>
              <div className="day-number">{dateText}</div>
              <div
                className="day-events"
                style={
                  date < 1 || date > daysInMonth
                    ? { display: "none" }
                    : { display: "inline" }
                }
              >
                {countOfSchedule > 0 && <span className="third-color"></span>}
              </div>
              <div className="large-details">
                {dateSchedule && (
                  <div className="events in new">
                    {dateSchedule.map((event, i) => (
                      <div className="large-event" key={event.name}>
                        <div>{event.name}</div>
                        <span>
                          {event.time.from ? event.time.from : ""}{" "}
                          {event.time.to ? ` - ${event.time.to} ` : ""}
                        </span>
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </div>
          </>
        );
      })}
      {selectedWeek === index && selectedSchedule.length > 0 && schedule && (
        <div className="details in">
          {selectedSchedule.map((s) => (
            <div className="events in new" key={s.id}>
              {s.events.map((event, i) => (
                <div className="event" key={event.name}>
                  <div>{event.name}</div>
                  <span>
                    {event.time.from ? event.time.from : ""}{" "}
                    {event.time.to ? ` - ${event.time.to} ` : ""}
                  </span>
                </div>
              ))}
            </div>
          ))}
        </div>
      )}
    </div>
  ));

  if (error)
    return (
      <h2 className="text-center" style={{ minHeight: "200px" }}>
        Something went wrong!!!
      </h2>
    );

  if (loading)
    return (
      <h2 className="text-center" style={{ minHeight: "200px" }}>
        Loading...
      </h2>
    );

  return (
    <div className="calendar-background">
      <div id="calendar">
        <div className="header">
          <h1>{currentYearMonth}</h1>
          <div className="left" onClick={() => handleDateChange(-1)}></div>
          <div className="right" onClick={() => handleDateChange(1)}></div>
        </div>
        <div className="month in next">{dateBoxes}</div>
      </div>
    </div>
  );
}
export default Schedule;
