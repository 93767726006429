import { HashLink as Link } from "react-router-hash-link";

/* eslint-disable jsx-a11y/anchor-is-valid */
function Footer() {
  return (
    <>
      <div id="section-footer">
        <footer className="sigma_footer style-5 pb-0">
          <div className="container">
            <div className="sigma_info-wrapper style-24 mb-5">
              <div className="sigma_info style-24">
                <div className="sigma_info-title">
                  <span className="sigma_info-icon">
                    <i className="fal fa-map-marker-alt"></i>
                  </span>
                </div>
                <div className="sigma_info-description">
                  <p className="secondary-color">
                    <a href="http://maps.google.com/?q=4303%2078%20Ave%20NW,%20Edmonton,%20AB%20T6B%202N3">
                        <b>Office: </b>4303 78 Ave NW, Edmonton, AB
                    </a>
                  </p>
					<p className="secondary-color">
                    <a href="http://maps.google.com/?q=7431%208%20St%20NW,%20Edmonton,%20AB%20T6T%201T9">
						<b>Yard: </b>7431 8 St NW, Edmonton, AB
                    </a>
                  </p>
                </div>
              </div>
              <div className="sigma_info style-24">
                <div className="sigma_info-title">
                  <span className="sigma_info-icon">
                    <i className="fal fa-phone"></i>
                  </span>
                </div>
                <div className="sigma_info-description">
                  <p className="secondary-color">
                    <a href={`tel:+1(780)655-4933`}>
                      +1(780)655-4933
                    </a>
                  </p>
					<p className="secondary-color">
                    <a href={`tel:+1(780)463-7773`}>
                      +1(780)463-7773
                    </a>
                  </p>
                </div>
              </div>
              <div className="sigma_info style-24">
                <div className="sigma_info-title">
                  <span className="sigma_info-icon">
                    <i className="fal fa-envelope"></i>
                  </span>
                </div>
                <div className="sigma_info-description">
                  <p className="secondary-color">
                    <a href={`mailto:agdriveredu@gmail.com`}>
                      agdriveredu@gmail.com
                    </a>
                  </p>
					<p className="secondary-color">
                    <a href={`mailto:info@agdrivingedu.ca`}>
                      info@agdrivingedu.ca
                    </a>
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="sigma_footer-middle">
            <div className="container">
              <div className="row">
                <div className="col-lg-4">
                  <div className="sigma_footer-widget">
                    <div className="sigma_footer-logo mb-4">
                      <img src="assets/img/logo.jpg" alt="logo" />
                    </div>
                    <div className="row">
                      <div className="col-sm-10">
                        <p className="mb-0">
                          Take the road to success with AG Driving Education!
                        </p>
                      </div>
                    </div>
                    <ul className="sigma_social-icons has-border mt-4 justify-content-start">
                      <li>
                        <a
                          href="https://www.facebook.com/AGDrivingEducation"
                          target="_blank"
                          rel="noreferrer"
                        >
                          <i className="fab fa-facebook-f"></i>
                        </a>
                      </li>
                      {/* <li>
												<a href="#">
													<i className="fab fa-twitter"></i>
												</a>
											</li> */}
                      <li>
                        <a
                          href="https://www.instagram.com/ag_driving_education"
                          target="_blank"
                          rel="noreferrer"
                        >
                          <i className="fab fa-instagram"></i>
                        </a>
                      </li>
                      {/* <li>
												<a href="#">
													<i className="fab fa-linkedin"></i>
												</a>
											</li>
											<li>
												<a href="#">
													<i className="fab fa-google"></i>
												</a>
											</li> */}
                    </ul>
                  </div>
                </div>
                <div className="col-lg-2 col-sm-3">
                  <div className="sigma_footer-widget">
                    <h5 className="widget-title">Services</h5>
                    <ul className="sigma_footer-links">
                      <li>
                        <a href="/courses">Courses and Fees</a>
                      </li>
                      <li>
                        <Link to="/#section-team">Instructors</Link>
                      </li>
                      <li>
												<Link to="lcvtraining">LCV Training</Link>
											</li>
                      <li>
                        <Link to="/schedule">Our Schedule</Link>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col-lg-2 col-sm-3">
                  <div className="sigma_footer-widget">
                    <h5 className="widget-title">Useful Links</h5>
                    <ul className="sigma_footer-links">
                      <li>
                        <Link to="/">Home</Link>
                      </li>
                      <li>
                        <Link to="/#section-about">About</Link>
                      </li>
                      <li>
                        <Link to="/financing">Financing</Link>
                      </li>
                      <li>
                        <Link to="/studentwall">Student Wall</Link>
                      </li>
                      <li>
                        <a
                          href="https://www.bistrainer.com/store/ag-driving-education-store/home"
                          target="_blank"
                          rel="noreferrer"
                        >
                          Online Training
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col-lg-2 col-sm-3">
                  <div className="sigma_footer-widget">
                    <h5 className="widget-title">Practice Tests</h5>
                    <ul className="sigma_footer-links">
                      <li>
                        <Link to="/abQuiz">Air Brakes Test</Link>
											</li>
                      <li>
                        <Link to="/kbQuiz">Knowledge Test</Link>
											</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="container">
            <div className="sigma_footer-bottom d-block d-sm-flex align-items-center justify-content-between">
              <div className="sigma_footer-copyright mt-0 mb-3 mb-sm-0">
                <p className="mb-0">
                  &copy; {new Date().getFullYear()} | AG Driving Education Inc.
                  | All Rights Reserved
                </p>
              </div>
              <ul className="sigma_footer-links">
                <li>
                  Powered by:{" "}
                  <a href="https://www.code-commit.com">
                    <b>
                      <u>Code Commit Inc.</u>
                    </b>
                  </a>
                </li>
                {/* <li>
									<a href="about-us.html">Terms</a>
								</li>
								<li>
									<a href="about-us.html">Sitemap</a>
								</li>
								<li>
									<Link to="/contact">Contact us</Link>
								</li> */}
              </ul>
            </div>
          </div>
        </footer>
      </div>
    </>
  );
}

export default Footer;
