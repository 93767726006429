import { useEffect } from "react";

function Financing() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return(
    <>
    <div id="section-subheader">
      <div className="sigma_subheader style-8 bg-cover bg-center dark-overlay"
      style={{ backgroundImage: 'url(assets/img/finance/1920x800.jpg)' }}>
        <div className="container">
          <div className="sigma_subheader-inner">
            <h1 className="sigma_stroke-text">Financing</h1>
          </div>
        </div>
      </div>
    </div>
    {/* <div id="section-pricing">
      <div className="section section-padding">
        <div className="container">
          <div className="section-title centered has-element">
            <h3 className="title">Our Pricing</h3>
            <div className="d-flex align-items-center justify-content-center">
              <span className="lines"></span>
              <span className="circle"></span>
              <span className="lines"></span>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-4 col-md-6">
              <div className="sigma_pricing style-3">
                <div className="sigma_pricing-top">
                  <span className="sigma_pricing-title">Professional Driver Improvement Course (PDIC) - Proctored</span>
                  <span className="sigma_pricing-rate">$149.99</span>
                  {/* <p className="m-0">One Time Installation</p> 
                </div>
                <div className="sigma_general-list style-3">
                  <p>This Professional Driver Improvement Course (PDIC) is specifically designed for drivers in the transportation industry as both a refresher on safe driving attitudes and techniques, and an introduction to new safe driving concepts. This program is Alberta Government approved for 3 demerit reduction upon successful completion of this course with a minimum 80% passing grade.</p>
                </div>
                <a href='/schedule' className="sigma_btn">Check Availablity</a>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="sigma_pricing style-3">
                <div className="sigma_pricing-top">
                  <span className="sigma_pricing-title">Professional Driver Improvement Course (PDIC) - Condensed (Alberta)</span>
                  <span className="sigma_pricing-rate">$69.99</span>
                  <p className="m-0">One Time Installation</p>
                </div>
                <div className="sigma_general-list style-3">
                  <p>This course is the condensed version of the Fleet Safety International Professional Driver Improvement Course (PDIC) and is developed specifically for professional truck drivers.</p>
                </div>
                <a href='/schedule' className="sigma_btn">Check Availablity</a>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="sigma_pricing style-3">
                <div className="sigma_pricing-top">
                  <span className="sigma_pricing-title">Professional Driver Improvement Course (PDIC) - 3 Demerit (Alberta)</span>
                  <span className="sigma_pricing-rate">$129.99</span>
                   <p className="m-0">One Time Installation</p> 
                </div>
                <div className="sigma_general-list style-3">
                    <p>This Professional Driver Improvement Course (PDIC) is specifically designed for drivers in the transportation industry as both a refresher on safe driving attitudes and techniques, and an introduction to new safe driving concepts. This program is Alberta Government approved for 3 demerit reduction upon successful completion of this course with a minimum 80% passing grade.</p>
                </div>
                <a href='/schedule' className="sigma_btn">Check Availablity</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div> */}
    <div id="section-financing">
      <div className="section section-padding">
        <div className="container">
          <div className="section-title centered has-element">
            <h3 className="title">Financing Options</h3>
            <div className="d-flex align-items-center justify-content-center">
              <span className="lines"></span>
              <span className="circle"></span>
              <span className="lines"></span>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-6 col-md-6">
              <div className="sigma_pricing style-3">
                <div className="sigma_pricing-top">
                  {/* <span className="sigma_pricing-title">Silver Plan</span> */}
                  <span className="sigma_pricing-rate">LendCare</span>
                  <p className="m-0">Works with businesses in the Automotive</p>
                </div>
                <div className="sigma_general-list style-3">
                  <ul>
                    <li>
                      <i className="far fa-check"></i>
                      <span>100% Online Process</span>
                    </li>
                    <li>
                      <i className="far fa-check"></i>
                      <span>7500 Merchant Partners</span>
                    </li>
                    <li>
                      <i className="far fa-check"></i>
                      <span>11+ Years As A Canadian Powersports Lender</span>
                    </li>
                    <li>
                      <i className="far fa-check"></i>
                      <span>$10B In Processed Loan Applications</span>
                    </li>
                    {/* <li>
                      <i className="far fa-check"></i>
                      <span>SMS reminders</span>
                    </li> */}
                  </ul>
                </div>
                <a href='https://www.lendcare.ca' className="sigma_btn" target="_blank" rel="noreferrer">Check Details</a>
              </div>
            </div>
            <div className="col-lg-6 col-md-6">
              <div className="sigma_pricing style-3">
                <div className="sigma_pricing-top">
                  {/* <span className="sigma_pricing-title">Gold Plan</span> */}
                  <span className="sigma_pricing-rate">Driving Back To Work Grant Program</span>
                  <p className="m-0">Govt. of Alberta Program</p>
                </div>
                <div className="sigma_general-list style-3">
                  <ul>
                    <li>
                      <i className="far fa-check"></i>
                      <span>Supports Unemployed And Underemployed Albertans</span>
                    </li>
                    <li>
                      <i className="far fa-check"></i>
                      <span>Class 1 Training Grant</span>
                    </li>
                    <li>
                      <i className="far fa-check"></i>
                      <span>Commercial Drivers Training</span>
                    </li>
                    {/* <li>
                      <i className="far fa-check"></i>
                      <span>Practical sessions</span>
                    </li>
                    <li>
                      <i className="far fa-check"></i>
                      <span>Psychological support</span>
                    </li> */}
                  </ul>
                </div>
                <a href='https://www.alberta.ca/driving-back-to-work-grant-program' className="sigma_btn" target="_blank" rel="noreferrer">Apply Now</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div id="section-to-top">
      <div className="sigma_top">
        <i className="far fa-arrow-up"></i>
      </div>
    </div>
    </>
  );
}

export default Financing;