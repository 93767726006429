/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect, useState } from 'react';
import './schedule.css';
import DatePicker from 'react-datepicker';

import 'react-datepicker/dist/react-datepicker.css';
import TimePicker from 'rc-time-picker';
import 'rc-time-picker/assets/index.css';

const UpdateSchedule = () => {
	const [name, setName] = useState('');
	const [startTime, setStartTime] = useState('');
	const [endDate, setEndTime] = useState('');
	const [date, setDate] = useState('');
	const [error, setError] = useState(false);
	const [success, setSuccess] = useState(false);
	const [schedule, setSchedule] = useState([]);
	const format = 'h:mm a';
	const onStartChange = (value) => {
		console.log(value && value.format(format));
		setStartTime(value.format(format));
	};

	const onEndChange = (value) => {
		console.log(value && value.format(format));
		setEndTime(value.format(format));
	};

	useEffect(() => {
		const fetchQuestions = async () => {
			try {
				const response = await fetch(
					`${process.env.REACT_APP_API_URL}schedule.json`
				);
				const { data, status } = await response.json();
				if (status === 'OK' && data.length > 0) {
					setSchedule(data || []);
				} else {
					setError(`Error: ${status}`);
				}
			} catch (err) {
				setError('Error fetching questions.');
			}
		};
		fetchQuestions();
	}, []);

	const handleSubmit = (e) => {
		e.preventDefault();
		let msg = '';
		if (!date) {
			msg += `Please add date`;
		}
		if (!name) {
			msg += ` Please add name`;
		}

		if (msg !== '') {
			alert(msg);
			return;
		}

		downloadJsonFile({
			id: date.toLocaleDateString(),
			events: [
				{ name, instructor: '', time: { from: startTime, to: endDate } },
			],
		});
	};

	const downloadJsonFile = ({ id, events }) => {
		let record = schedule.find((r) => r.id === id);
		if (!record) {
			record = { id };
			schedule.push(record);
		}

		record.events = events;
		// Step 2: Convert the JSON object to a string
		const jsonData = JSON.stringify(schedule);

		// Step 3: Create a Blob from the JSON string
		const blob = new Blob([jsonData], { type: 'application/json' });

		// Step 4: Create a URL for the Blob
		const url = URL.createObjectURL(blob);

		// Step 5: Create an anchor element and trigger a download
		const a = document.createElement('a');
		a.href = url;
		a.download = 'data.json'; // This is the name of the file
		a.click();

		// Clean up the URL object
		URL.revokeObjectURL(url);
		setSuccess(true);
	};

	if (error)
		return (
			<div style={{ display: 'flex', justifyContent: 'center' }}>
				<h2>Something went wrong!!!</h2>
			</div>
		);

	return (
		<>
			{success ? (
				<div style={{ display: 'flex', justifyContent: 'center' }}>
					<h2>Added Successfully</h2>
				</div>
			) : (
				<form onSubmit={handleSubmit}>
					<div>
						<label>Date</label>
						<DatePicker selected={date} onChange={(date) => setDate(date)} />
					</div>
					<div>
						<label>Name</label>
						<input
							type="text"
							value={name}
							onChange={(e) => setName(e.target.value)}
						/>
					</div>
					<div>
						<label>Start Time</label>
						<TimePicker
							showSecond={false}
							onChange={onStartChange}
							format={format}
							use12Hours
							inputReadOnly
						/>
					</div>
					<div>
						<label>End Time</label>
						<TimePicker
							showSecond={false}
							onChange={onEndChange}
							format={format}
							use12Hours
							inputReadOnly
						/>
					</div>
					<input type="submit" />
				</form>
			)}
		</>
	);
};
export default UpdateSchedule;
