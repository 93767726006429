import { useEffect } from "react";
import About from "./about";
// import Blog from './blog';
// import CTA from './cta';
import CounterStart from "./counterStart";
import Features from "./features";
import Hero from "./hero";
// import Pricing from './pricing';
import Services from "./services";
import Team from "./team";
import Testimonial from "./testimonial";
import ToTop from "./toTop";

function Home() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Hero />
      <About />
      <Services />
      <Features />
      <Team />
      <Testimonial placeId={"ChIJLUOhHUQZoFMR-7NhoEe4IZI"} />
      <CounterStart />
      <ToTop />
    </>
  );
}

export default Home;
