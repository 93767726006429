function Preload() {
	return (
		<>
			<div id="section-preloader">
				<div className="sigma_preloader sigma_preloader-squares">
					<div className="sigma_preloader-inner">
						<div className="preloader-squares">
							<div className="preloader-squares-inner">
								<div></div>
								<div></div>
								<div></div>
								<div></div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
}

export default Preload;
