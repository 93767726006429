import Slider from "react-slick";

function Hero() {
  const settings = {
    dots: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    speed: 2000,
    autoplaySpeed: 2000,
    cssEase: "linear",
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <>
      <div id="section-hero">
        <div className="sigma_banner">
          <div className="sigma_banner-slider">
            <Slider {...settings} pauseOnHover dots={false}>
              <div
                className="banner-slider-inner bg-center bg-cover dark-overlay img-first"
                style={{
                  backgroundImage:
                    "url(assets/img/home-2/1920x800.jpg) !important",
                }}
              >
                <div className="sigma_banner-text text-center">
                  <div className="container">
                    <div className="row justify-content-center">
                      <div className="col-lg-8">
                        <h1 className="title text-white">
                          Helping You To Become An Expert Driver
                        </h1>
                        <p className="text-white">
                          {/* Dlearn is responsive HTML 5 website template build with
												latest Bootstrap 3 Framework, and specially designed to
												demonstrate your company’s driving school lessons. */}
                        </p>
                        <form method="post" className="banner-links">
                          <div className="row justify-content-center">
                            <div className="col-md-6">
                              {/* <input type="email" name="email" placeholder="Enter your email"> */}
                            </div>
                            {/* <div className="col-md-3">
														<button
															type="button"
															className="btn-block mt-4 mt-md-0"
														>
															Submit
														</button>
													</div> */}
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="banner-slider-inner bg-center bg-cover dark-overlay img-second"
                style={{
                  backgroundImage: "url(assets/img/home-2/1920x800-0.jpg)",
                }}
              >
                <div className="sigma_banner-text text-center">
                  <div className="container">
                    <div className="row justify-content-center">
                      <div className="col-lg-8">
                        <h1 className="title text-white">
                          A Great Provider for Truck Driving School
                        </h1>
                        <p className="text-white">
                          {/* Dlearn is responsive HTML 5 website template build with
												latest Bootstrap 3 Framework, and specially designed to
												demonstrate your company’s driving school lessons. */}
                        </p>
                        <form method="post" className="banner-links">
                          <div className="row justify-content-center">
                            <div className="col-md-6">
                              {/* <input type="email" name="email" placeholder="Enter your email"> */}
                            </div>
                            {/* <div className="col-md-3">
														<button
															type="button"
															className="btn-block mt-4 mt-md-0"
														>
															Submit
														</button>
													</div> */}
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Slider>
          </div>
        </div>
      </div>
    </>
  );
}

export default Hero;
