import { HashLink as Link} from 'react-router-hash-link';

function About() {
  return (
    <>
      <div id="section-about">
        <div className="section">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-5">
                <div className="sigma_about style-14 w-100 h-100">
                  <div className="sigma_about-image-1">
                    <img src="assets/img/home-2/445x530.jpg" alt="img" />
                  </div>
                  <div className="sigma_media">
                    <span className="about-counter mb-2">
                      <b className="counter" data-from="0" data-to="15">
												15
                      </b>
                      <span>+</span>
                    </span>
                    <p className="mb-0">Years of Experience</p>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 offset-lg-1">
                <div className="sigma_about style-14">
                  <div className="section-title">
                    <span className="subtitle">About Us</span>
                    <h3 className="title">
											Learning Experience For Much Easier Driving
                    </h3>
                  </div>
                  <div className="sigma_about-content">
                    <p className="mb-4">
                    At AG Truck Driving Education Inc., we are committed to providing top-notch training and education for aspiring truck drivers. With a focus on excellence, safety, and industry-leading instruction, we equip our students with the skills and knowledge they need to thrive in the trucking profession.
                    </p>

                    <h3>Our Mission</h3>
                    <p>Our mission is to empower individuals with the expertise required to succeed in the trucking industry. We strive to deliver comprehensive, hands-on training that meets the highest standards of safety and professionalism, ensuring that our graduates are well-prepared for the demands of their careers.</p>
                    <div className="row">
                      <div className="col-md-6">
                        <div className="sigma_info style-15">
                          <div className="sigma_info-title">
                            <span className="sigma_info-icon">
                              <i className="fal fa-check"></i>
                            </span>
                          </div>
                          <div className="sigma_info-description">
                            <h5 className="mb-0">
															Learn from Experienced Instructors
                            </h5>
                          </div>
                        </div>
                        {/* <p className="mb-0">
													We’re an Experienced, Trustworthy, Reliable & Friendly
													Driving School.
                        </p> */}
                      </div>
                      <div className="col-md-6">
                        <div className="sigma_info style-15">
                          <div className="sigma_info-title">
                            <span className="sigma_info-icon">
                              <i className="fal fa-check"></i>
                            </span>
                          </div>
                          <div className="sigma_info-description">
                            <h5 className="mb-0">
															Covering All Aspects of Safe Driving
                            </h5>
                          </div>
                        </div>
                        {/* <p className="mb-0">
													We’re an Experienced, Trustworthy, Reliable & Friendly
													Driving School.
                        </p> */}
                      </div>
                    </div>
                    <Link to="/contact" className="sigma_btn">
											Contact Us
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default About;
