function ToTop() {
  return (
    <div id="section-to-top">
      <div className="sigma_top">
        <i className="far fa-arrow-up"></i>
      </div>
    </div>
  );
}
export default ToTop;
