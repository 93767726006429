import { Link } from "react-router-dom";

// eslint-disable-next-line react/prop-types
function AirBrakes({ allCourses }) {
  return (
    <>
      <div id="section-service-details">
        <div className="section sigma_post-details">
          <div className="container">
            <div className="row">
              <div className="col-lg-8">
                <div className="sigma_post-details-inner">
                  <div className="entry-content">
                    <div className="row"></div>
                    <h2 className="entry-title">Air Brakes Certification</h2>
                    <p>
                      The Alberta Air Brakes Course is a specialized training
                      program designed to provide drivers with the knowledge and
                      skills required to safely operate vehicles equipped with
                      air brake systems. Air brakes are commonly used in large
                      trucks, buses, and other heavy vehicles, making this
                      course a crucial step for drivers seeking to enhance their
                      qualifications and ensure road safety.
                    </p>
                    {/* <p>The Class 1 MELT course is typically delivered through a combination of classroom instruction and practical, on-road training. It’s essential for meeting the requirements set by transportation authorities in various regions and ensuring that drivers have the skills and knowledge needed for a successful career in commercial driving.</p> */}
                    <img src="assets/img/services/370x240-11.jpg" alt="post" />
                    {/* <h4>When You Start Learning To Drive, It Can Be A Little Tuff</h4>
                <p>
                  New drivers the theory and techniques needed to carefully operate various types of vehicles such as cars, motorcycles, trucks and buses. A driving instructor has several names and can be recognized as Auto Driving Instructor, Educator, Instructor, Teacher or Truck Driving Instructor Teenager Danny wanted to pass his test so he could visit his girlfriend in Preston Although he passed first time, he had already.
                </p>
                <hr/>
                <h4>Take Driving lessons with a private tutor</h4>
                <p>
                  The duties and responsibilities of a driving instructor are to manage the theory className timings, teach the driving students about the right traffic rules and inform student drivers with safety driving procedures. In addition to that, driving instructors supply their students with information about emergency situations After the classNamees about theory knowledge, driving instructors help their driving students in practically learning how to drive a car individually. At the beginning, the driving instructor teaches the student how to use a steering wheel, how to reverse and how to park In addition, the driving instructor.
                </p> */}
                  </div>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="sidebar mt-5 mt-lg-0">
                  <div className="widget widget-blockquote">
                    <blockquote className="blockquote">
                      <cite>$250.00</cite>
                      <cite>1 Day</cite>
                      <p>Contact us to Book your next slot!</p>
                      <Link to="/contact" className="sigma_btn btn-sm">
                        Contact Us
                      </Link>
                    </blockquote>
                  </div>
                  {allCourses}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="section-to-top">
        <div className="sigma_top">
          <i className="far fa-arrow-up"></i>
        </div>
      </div>
    </>
  );
}

export default AirBrakes;
