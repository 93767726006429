/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import { HashLink as Link } from "react-router-hash-link";
import Calendar from "./Schedule/schedule";
import $ from "jquery";

/* eslint-disable jsx-a11y/anchor-is-valid */
function Header() {
  const toggleSideMenu = (event, flag) => {
    event.stopPropagation();
    $("body").toggleClass(flag ? "aside-right-open" : "aside-open");
  };

  const removeSideMenu = () => {
    if ($("body").hasClass("aside-right-open"))
      $("body").toggleClass("aside-right-open");
    if ($("body").hasClass("aside-open")) $("body").toggleClass("aside-open");
  };

  return (
    <>
      <div id="section-header" onClick={() => removeSideMenu(false)}>
        <aside className="sigma_aside sigma_aside-desktop">
          <div className="sidebar">
            <div className="widget widget-follow">
              <h5 className="widget-title">Follow Us</h5>
              <div className="social-buttons">
                <a
                  href="https://www.instagram.com/ag_driving_education"
                  className="btn-block sigma_btn"
                  target="_blank"
                  rel="noreferrer"
                >
                  <div className="follow-icon d-flex align-items-center">
                    <i className="fab fa-instagram"></i>
                    <p className="mb-0">
                      Instagram
                      <span>ag_driving_education</span>
                    </p>
                  </div>
                  <i className="fal fa-chevron-right"></i>
                </a>
                <a
                  href="https://www.facebook.com/AGDrivingEducation"
                  className="btn-block sigma_btn"
                  target="_blank"
                  rel="noreferrer"
                >
                  <div className="follow-icon d-flex align-items-center">
                    <i className="fab fa-facebook-f"></i>
                    <p className="mb-0">
                      Facebook
                      <span>AG Driving Education</span>
                    </p>
                  </div>
                  <i className="fal fa-chevron-right"></i>
                </a>
              </div>
            </div>
            <div className="widget widget-follow">
              <h5 className="widget-title">Our Schedule</h5>
              <Calendar />
            </div>
          </div>
        </aside>
        <div className="sigma_aside-overlay aside-trigger-right"></div>
        <aside className="sigma_aside">
          <div className="sigma_logo-wrapper">
            <a className="sigma_logo" href="/">
              <img src="assets/img/logo.jpg" alt="logo" />
            </a>
          </div>
          <ul className="navbar-nav">
            <li className="menu-item">
              <Link to="/">Home</Link>
            </li>
            <li className="menu-item menu-item-has-children">
              <a
                href={() => false}
                onClick={(e) => {
                  e.stopPropagation();
                }}
              >
                Courses Details
              </a>
              <ul className="sub-menu">
                <li className="menu-item">
                  <Link to="/courses">Courses and Fees</Link>
                </li>
                <li className="menu-item">
                  <a
                    href="https://www.bistrainer.com/store/ag-driving-education-store/home"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Online Trainings
                  </a>
                </li>
                <li className="menu-item">
                  <Link to="/lcvtraining">LCV Training</Link>
                </li>
              </ul>
            </li>
            <li className="menu-item">
              <Link to="/schedule">Schedule</Link>
            </li>
            <li className="menu-item">
              <Link to="/financing">Financing</Link>
            </li>
            <li className="menu-item">
              <Link to="/studentwall">Student Wall</Link>
            </li>
            {/* <li className="menu-item menu-item-has-children">
              <a
                href={() => false}
                onClick={(e) => {
                  e.stopPropagation();
                }}
              >
                Trainings
              </a>
              <ul className="sub-menu">
                <li className="menu-item">
                  <a
                    href="https://www.bistrainer.com/store/ag-driving-education-store/home"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Online Training
                  </a>
                </li>
                <li className="menu-item">
                  <a href="/lcvtraining">LCV Training</a>
                </li>
              </ul>
            </li> */}
            <li className="menu-item menu-item-has-children">
              <a
                href={() => false}
                onClick={(e) => {
                  e.stopPropagation();
                }}
              >
              Practice Tests</a>
              <ul className="sub-menu">
                <li className="menu-item">
                  <Link to="/abQuiz">Air Brakes Test</Link>
                </li>
                <li className="menu-item">
                  <Link to="/kbQuiz">Knowledge Test</Link>
                </li>
              </ul>
            </li>
            <li className="menu-item">
              <Link to="/#section-about">About Us</Link>
            </li>
            <li className="menu-item">
              <Link to="/contact">Contact Us</Link>
            </li>
          </ul>
        </aside>
        <div className="sigma_aside-overlay aside-trigger"></div>
        <header className="sigma_header style-5 can-sticky">
          <div className="sigma_header-top dark-bg d-md-block">
            <div className="container">
              <div className="sigma_header-top-inner">
                <div className="sigma_header-top-links">
                  {/* <ul className="sigma_header-top-nav"> */}
                  {/* <li> */}
                  <a href={`tel:+1(780)655-4933`} className="">
                    {" "}
                    Call <b>+1(780)655-4933</b> for next slots.
                  </a>
                  {/* </li> */}
                  {/* <li>
											<a href="#">
												<i className="fal fa-map-marker-alt"></i>
												4303 78 Ave NW, Edmonton, AB, T6B 2N3
											</a>
										</li> */}
                  {/* </ul> */}
                </div>
                <div className="sigma_header-top-contacts">
                  <ul className="sigma_header-top-nav">
                    <li>
                      {" "}
                      <a
                        href="https://www.facebook.com/AGDrivingEducation"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <i className="fab fa-facebook-f"></i>
                      </a>
                    </li>
                    {/* <li>
											{' '}
											<a href="#">
												<i className="fab fa-tiktok"></i>
											</a>
										</li>
										<li>
											{' '}
											<a href="#">
												<i className="fab fa-linkedin-in"></i>
											</a>
										</li> */}
                    <li>
                      <a
                        href="https://www.instagram.com/ag_driving_education"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <i className="fab fa-instagram"></i>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="sigma_header-middle">
            <div className="container">
              <div className="navbar">
                <div className="sigma_logo-wrapper">
                  <a className="sigma_logo" href="/">
                    <img src="assets/img/logo.jpg" alt="logo" />
                  </a>
                </div>
                <div className="d-none d-lg-flex align-items-center">
                  <div className="sigma_header-top-links important-links">
                    <ul className="sigma_header-top-nav">
                      <li>
                        <a href={`tel:+1(780)655-4933`}>
                          <i className="fal fa-phone"></i>
                          <div>
                            <p>
                              <a href={`tel:+1(780)655-4933`}>
                                Office:<p>+1(780)655-4933</p>
                              </a>
                            </p>
                            <p>
                              <a href={`tel:+1(780)463-7773`}>
                                Cell:<p>+1(780)463-7773</p>
                              </a>
                            </p>
                          </div>
                        </a>
                      </li>
                      <li>
                        <a href={`mailto:agdriveredu@gmail.com`}>
                          <i className="fal fa-envelope"></i>
                          <div>
                            <p>
                              <a href={`mailto:agdriveredu@gmail.com`}>
                                agdriveredu@gmail.com
                              </a>
                            </p>
                            <p>
                              <a href={`mailto:info@agdrivingedu.ca`}>
                                info@agdrivingedu.ca
                              </a>
                            </p>
                          </div>
                        </a>
                      </li>
                      <li>
                        <a
                          href="http://maps.google.com/?q=4303%2078%20Ave%20NW,%20Edmonton,%20AB%20T6B%202N3"
                          target="_blank"
                          rel="noreferrer"
                        >
                          <i className="fal fa-map-marker-alt"></i>
                          <div>
                            <p>
                              <a
                                href="http://maps.google.com/?q=4303%2078%20Ave%20NW,%20Edmonton,%20AB%20T6B%202N3"
                                target="_blank"
                                rel="noreferrer"
                              >
                                Office: 4303 78 Ave NW, Edmonton, AB
                              </a>
                            </p>
                            <p>
                              <a
                                href="http://maps.google.com/?q=7431%208%20St%20NW,%20Edmonton,%20AB%20T6T%201T9"
                                target="_blank"
                                rel="noreferrer"
                              >
                                Yard: 7431 8 St NW, Edmonton, AB
                              </a>
                            </p>
                          </div>
                        </a>
                      </li>
                      {/* <li>
                        <a
                          href="http://maps.google.com/?q=7431%208%20St%20NW,%20Edmonton,%20AB%20T6T%201T9"
                          target="_blank"
                          rel="noreferrer"
                        >
                          <i className="fal fa-map-marker-alt"></i>
                          <div>
                            <p>Yard Address</p>
                            <p>
                              <a
                                href="http://maps.google.com/?q=7431%208%20St%20NW,%20Edmonton,%20AB%20T6T%201T9"
                                target="_blank"
                                rel="noreferrer"
                              >
                                <b>
								7431 8 St NW, <br />
                                  Edmonton, AB, T6T IT9
                                </b>
                              </a>
                            </p>
                          </div>
                        </a>
                      </li> */}
                    </ul>
                  </div>
                  <Link to="/contact" className="sigma_btn ml-5">
                    Get in touch
                  </Link>
                </div>
                <div className="sigma_header-controls style-2 d-block d-lg-none">
                  <ul className="sigma_header-controls-inner">
                    <li
                      className="aside-toggle aside-trigger"
                      onClick={(event) => toggleSideMenu(event, false)}
                    >
                      <span></span>
                      <span></span>
                      <span></span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="sigma_header-bottom">
            <div className="container">
              <div className="navbar">
                <ul className="navbar-nav">
                  <li className="menu-item menu-item-has-children">
                    <Link to="/">Home</Link>
                    {/* <ul className="sub-menu">
											<li className="menu-item">
												<a href="/>Home 1</a>
											</li>
											<li className="menu-item">
												<a href="home-2.html">Home 2</a>
											</li>
										</ul> */}
                  </li>
                  <li className="menu-item menu-item-has-children">
                    <a
                      href={() => false}
                      onClick={(e) => {
                        e.stopPropagation();
                      }}
                    >
                      Courses Details
                    </a>
                    <ul className="sub-menu">
                      <li className="menu-item">
                        <Link to="/courses">Courses and Fees</Link>
                      </li>
                      <li className="menu-item">
                        <a
                          href="https://www.bistrainer.com/store/ag-driving-education-store/home"
                          target="_blank"
                          rel="noreferrer"
                        >
                          Online Trainings
                        </a>
                      </li>
                      <li className="menu-item">
                        <Link to="/lcvtraining">LCV Training</Link>
                      </li>
                    </ul>
                  </li>
                  {/* <li className="menu-item menu-item-has-children">
										<a href="portfolio.html">Portfolio</a>
										<ul className="sub-menu">
											<li className="menu-item">
												<a href="portfolio-details.html">Portfolio details</a>
											</li>
										</ul>
									</li> */}
                  <li className="menu-item menu-item-has-children">
                    <Link to="/schedule">Schedule</Link>
                  </li>
                  <li className="menu-item menu-item-has-children">
                    <Link to="/studentwall">Student Wall</Link>
                  </li>
                  <li className="menu-item menu-item-has-children">
                    <Link to="/financing">Financing</Link>
                  </li>
                  {/* <li className="menu-item menu-item-has-children">
                    <a href="#">Trainings</a>
                    <ul className="sub-menu">
                      <li className="menu-item">
                        <a
                          href="https://www.bistrainer.com/store/ag-driving-education-store/home"
                          target="_blank"
                          rel="noreferrer"
                        >
                          Online Training
                        </a>
                      </li>
                      <li className="menu-item">
                        <a href="/lcvtraining">LCV Training</a>
                      </li>
                    </ul>
                  </li> */}
                  <li className="menu-item menu-item-has-children">
                      <a
                        href={() => false}
                        onClick={(e) => {
                        e.stopPropagation();
                      }}
                      >
                      Practice Tests</a>
                    <ul className="sub-menu">
                      <li className="menu-item">
                        <Link to="/abQuiz">Air Brakes Test</Link>
                      </li>
                      <li className="menu-item">
                        <Link to="/kbQuiz">Knowledge Test</Link>
                      </li>
                    </ul>
                  </li>
                  <li className="menu-item menu-item-has-children">
                    <Link to="/#section-about">About Us</Link>
                  </li>
                  {/* <li className="menu-item menu-item-has-children">
										<a href="#">Pages</a>
										<ul className="sub-menu">
											<li className="menu-item">
												<a href="team.html">Instructors</a>
											</li>
											<li className="menu-item">
												<a href="about-us.html">About us</a>
											</li>
											<li className="menu-item">
												<Link to="/contact">Contact us</Link>
											</li>
										</ul>
									</li> */}
                  {/* <li className="menu-item menu-item-has-children">
										<a href="blog.html">Blog</a>
										<ul className="sub-menu">
											<li className="menu-item">
												<a href="blog-details.html">Blog details</a>
											</li>
										</ul>
									</li> */}
                  <li className="menu-item">
                    <Link to="/contact">Contact Us</Link>
                  </li>
                </ul>
                <div className="sigma_header-controls style-2">
                  <ul className="sigma_header-controls-inner">
                    <li className="search-trigger header-controls-item d-none d-sm-block">
                      {/* <a
												className="sigma_header-control-search"
												title="Search"
												href="#"
											>
												<i className="far fa-search"></i>
											</a> */}
                    </li>
                    <li
                      className="aside-toggle aside-trigger-right desktop-toggler"
                      onClick={(event) => toggleSideMenu(event, true)}
                    >
                      <span></span>
                      <span></span>
                      <span></span>
                    </li>
                    <li
                      className="aside-toggle aside-trigger"
                      onClick={(event) => toggleSideMenu(event, false)}
                    >
                      <span></span>
                      <span></span>
                      <span></span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </header>
        <div className="search-form-wrapper">
          <div className="search-trigger sigma_close">
            <span></span>
            <span></span>
          </div>
        </div>
      </div>
    </>
  );
}

export default Header;
