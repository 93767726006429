// eslint-disable-next-line react/prop-types
function Class3Advanced({ allCourses }) {
  return (
    <>
      <div id="section-service-details">
        <div className="section sigma_post-details">
          <div className="container">
            <div className="row">
              <div className="col-lg-8">
                <div className="sigma_post-details-inner">
                  <div className="entry-content">
                    <div className="row">
                      {/* <div className="col-md-4">
                    <a href="assets/img/service-details/240.jpg" className="gallery-thumb">
                      <img src="assets/img/service-details/240.jpg" alt="img"/>
                    </a>
                  </div>
                  <div className="col-md-4">
                    <a href="assets/img/service-details/240-0.jpg" className="gallery-thumb">
                      <img src="assets/img/service-details/240-0.jpg" alt="post"/>
                    </a>
                  </div>
                  <div className="col-md-4">
                    <a href="assets/img/service-details/240-1.jpg" className="gallery-thumb">
                      <img src="assets/img/service-details/240-1.jpg" alt="post"/>
                    </a>
                  </div> */}
                    </div>
                    <h2 className="entry-title">Class 3 Training - Advanced</h2>
                    <p>
                    Advanced Class 3 driver training is designed for those who have mastered the basics of operating a medium-duty truck and wish to refine their skills further. This level of training focuses on more complex driving scenarios, advanced vehicle handling, and in-depth regulatory compliance.
                    </p>
                    <p><b>Includes Air Brakes and Load Securement.</b></p>
                    <img src="assets/img/services/370x240-111.jpg" alt="post" />
                    {/* <h4>When You Start Learning To Drive, It Can Be A Little Tuff</h4>
                <p>
                  New drivers the theory and techniques needed to carefully operate various types of vehicles such as cars, motorcycles, trucks and buses. A driving instructor has several names and can be recognized as Auto Driving Instructor, Educator, Instructor, Teacher or Truck Driving Instructor Teenager Danny wanted to pass his test so he could visit his girlfriend in Preston Although he passed first time, he had already.
                </p>
                <hr/>
                <h4>Take Driving lessons with a private tutor</h4>
                <p>
                  The duties and responsibilities of a driving instructor are to manage the theory className timings, teach the driving students about the right traffic rules and inform student drivers with safety driving procedures. In addition to that, driving instructors supply their students with information about emergency situations After the classNamees about theory knowledge, driving instructors help their driving students in practically learning how to drive a car individually. At the beginning, the driving instructor teaches the student how to use a steering wheel, how to reverse and how to park In addition, the driving instructor.
                </p> */}
                  </div>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="sidebar mt-5 mt-lg-0">
                  <div className="widget widget-blockquote">
                    <blockquote className="blockquote">
                      <cite>$3,350.00</cite>
                      <cite>30.5 Hours</cite>
                      <p>
                        Ready to take the next step in your trucking career?{" "}
                      </p>
                      <p>Contact us to Book your next slot!</p>
                      <a href="/contact" className="sigma_btn btn-sm">
                        Book your slot
                      </a>
                    </blockquote>
                  </div>
                  {allCourses}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="section-to-top">
        <div className="sigma_top">
          <i className="far fa-arrow-up"></i>
        </div>
      </div>
    </>
  );
}

export default Class3Advanced;
