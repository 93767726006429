/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect, useState } from "react";
import "./quiz.css";
import PropTypes from "prop-types";

const Quiz = ({ quizFor }) => {
  const [questions, setQuestions] = useState([]);
  const [currentQuestion, setCurrentQuestion] = useState(null);
  const [result, setResult] = useState({ correct: 0, wrong: 0 });
  const [resultReady, setResultReady] = useState(false);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [optionAlreadySelected, setOptionAlreadySelected] = useState(null);

  const handleOptionSelection = (id, optionIndex) => {
    if (optionAlreadySelected) return;
    currentQuestion.selectedOption = optionIndex;
    setCurrentQuestion({ ...currentQuestion });
    setOptionAlreadySelected(true);
  };

  const handleSubmit = () => {
    if (currentQuestion.selectedOption === currentQuestion.answers)
      result.correct += 1;
    else result.wrong += 1;
    setResult({ ...result });
    setResultReady(true);
  };

  const handleNext = () => {
    if (currentQuestion.selectedOption === currentQuestion.answers)
      result.correct += 1;
    else result.wrong += 1;
    setResult({ ...result });
    setCurrentQuestion(questions.find((q) => q.id === currentQuestion.id + 1));
    setOptionAlreadySelected(false);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const fetchQuestions = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}${quizFor}.json`
        );
        const { data, status } = await response.json();
        if (status === "OK" && data.length > 0) {
          setQuestions(data || []);
          setCurrentQuestion(data[0]);
        } else {
          setError(`Error: ${status}`);
        }
        setLoading(false);
      } catch (err) {
        setError("Error fetching questions.");
        setLoading(false);
      }
    };
    fetchQuestions();
  }, [quizFor]);

  if (error)
    return (
      <h2 className="text-center" style={{ minHeight: "200px" }}>
        Something went wrong!!!
      </h2>
    );

  if (loading)
    return (
      <h2 className="text-center" style={{ minHeight: "200px" }}>
        Loading...
      </h2>
    );

  return (
    <div className="wrapper">
      <div className="container">
        <form
          className="multisteps_form position-relative"
          id="wizard"
          method="POST"
          action="../thankyou/index-2.html"
        >
          <div className="multisteps_form_panel" style={{ display: "block" }}>
            {!resultReady && questions.length > 0 && currentQuestion ? (
              <>
                <span className="question_number text-uppercase d-flex justify-content-center align-items-center">
                  Question {currentQuestion.id}/{questions.length}
                </span>
                <h3 className="question_title text-center">
                  {currentQuestion.question}
                </h3>
                {currentQuestion.hasImage && (
                  <div className="text-center">
                    <img
                      src={`${process.env.REACT_APP_API_URL}static/${currentQuestion.id}.png`}
                      alt="img"
                      style={{ width: "200px" }}
                    />
                  </div>
                )}
                <div className="form_items d-flex justify-content-center">
                  <ul className="ps-0 quiz-ul">
                    {currentQuestion.options.map((option, index) => (
                      <li
                        onClick={() =>
                          handleOptionSelection(currentQuestion.id, index)
                        }
                        className={`step_2 rounded-pill bg-white animate__animated animate__fadeInRight animate_50ms question-li 
                            ${currentQuestion.selectedOption > -1 && (option === currentQuestion.options[currentQuestion.selectedOption] || option === currentQuestion.options[currentQuestion.answers]) ? (currentQuestion.selectedOption === currentQuestion.answers || option === currentQuestion.options[currentQuestion.answers] ? "right-option" : "wrong-option") : ""}`}
                        key={index}
                      >
                        {option}
                      </li>
                    ))}
                  </ul>
                </div>
              </>
            ) : (
              questions.length > 0 && (
                <div className="result-panel" style={{ display: "block" }}>
                  <h2 className="question_title text-center">Your Score</h2>
                  <h3 className="text-center">Correct : {result.correct} </h3>
                  <h3 className="text-center">Wrong : {result.wrong}</h3>
                </div>
              )
            )}
          </div>
          <div className="form_btn text-center ms-5 mt-5">
            {currentQuestion?.id < questions.length
              ? currentQuestion?.selectedOption !== -1 && (
                  <button
                    type="button"
                    className="btn-block"
                    id="nextBtn"
                    onClick={() => handleNext()}
                  >
                    Next Question
                  </button>
                )
              : !resultReady &&
                currentQuestion?.selectedOption !== -1 && (
                  <button
                    type="button"
                    className="btn-block"
                    id="nextBtn"
                    onClick={() => handleSubmit()}
                  >
                    Submit
                  </button>
                )}
          </div>
        </form>
      </div>
    </div>
  );
};

Quiz.propTypes = {
  quizFor: PropTypes.oneOf(["air-brake-quiz", "knowledge-quiz"]),
};

export default Quiz;
