function CounterStart() {
  return (
    <>
      <div id="section-counter">
        <div
          className="section section-padding bg-cover bg-center dark-overlay"
          style={{ backgroundImage: "url(assets/img/home-2/1920x500.jpg)" }}
        >
          <div className="container">
            <div className="row">
              <div className="col-md-4 col-sm-6">
                <div className="sigma_counter text-center text-sm-left">
                  <span className="justify-content-center justify-content-sm-start">
                    <b className="counter" data-from="0" data-to="150">
											150
                    </b>
                    <span className="plus">+</span>
                  </span>
                  <p className="text-white">Trainings Completed Per Month</p>
                </div>
              </div>
              <div className="col-md-4 col-sm-6">
                <div className="sigma_counter text-center text-sm-left">
                  <span className="justify-content-center justify-content-sm-start">
                    <b className="counter" data-from="0" data-to="15">
											15
                    </b>
                    <span className="plus">+</span>
                  </span>
                  <p className="text-white">Years Experienced Instructors</p>
                </div>
              </div>
              <div className="col-md-4 col-sm-6">
                <div className="sigma_counter text-center text-sm-left">
                  <span className="justify-content-center justify-content-sm-start">
                    <b className="counter" data-from="0" data-to="10">
											10
                    </b>
                    <span className="plus">+</span>
                  </span>
                  <p className="text-white">Awesome Team Members</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default CounterStart;
